import axios from "axios";

export enum Registration {
  DataSet,
  Model,
}

export enum Lod {
  Overall,
  Middle,
  Detail1,
  Detail2,
  Detail3,
}

export enum DeleteStatus {
  NONE,
  SUCCUSS,
  ERROR,
}

export const zf = 30;

export const initialValue = {
  dataSets: [
    {
      dataSetName: "",
      dataSetId: "",
      createdAt: "",
    },
  ],
  models: [
    {
      modelId: "",
      modelName: "",
      createdAt: "",
      state: "",
    },
  ],
  selected: {
    dataSet: {
      dataSetName: "",
      dataSetId: "notFound",
      createdAt: "",
    },
    model: {
      modelId: "",
      modelName: "",
      createdAt: "",
      state: "",
    },
    plot: [
      {
        id: 0,
        coordinate: [0, 0],
        cluster_id: [0],
        text: "",
      },
    ],
    labels: [
      {
        cluster_id: 0,
        words: ["", "", ""],
        coordinate: [0, 0],
        size: 0,
        range: [0, 0],
        color: "",
        hue: 0,
        saturation: 50,
        lightness: 75,
      },
    ],
  },
  getModels: (dataSetId: string) => {
    console.log(dataSetId);
  },
  deleteDataSet: (dataSetId: string) => {
    console.log(dataSetId);
  },
  uploadDataSet: async ({ dataSetName, csvFile }: uploadDataSet) => {
    const data = new FormData();
    data.append("csvfile", csvFile);
    data.append("dataset", dataSetName);

    const uploadDataSetRes = await axios
      .post("" + "api/dataset", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer `,
        },
      })
      .catch((e) => {
        console.log("APIError", e);
        return e.response;
      });
    if (uploadDataSetRes.status !== 200) return;

    console.log("uploadDataSetRes", uploadDataSetRes);
    const dataSetRes = await axios
      .get("" + "api/dataset", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer `,
        },
      })
      .catch((e) => {
        console.log("APIError", e);
        return e.response;
      });
    if (dataSetRes.status !== 200) return;

    const dataSet = dataSetRes.data.filter((dataSet: dataSetAPI) => {
      return dataSet.dataset === dataSetName;
    })[0];

    return dataSet;
  },
  createModel: async ({ dataSetId, modelName, modelParams }: createModel) => {
    const data = {
      dataset_id: dataSetId,
      model: modelName,
      model_params: {
        flow: modelParams.flow,
        doc2vec: {
          seed: modelParams.doc2vec.seed,
          vector_size: modelParams.doc2vec.vectorDim,
          epochs: modelParams.doc2vec.epoch,
        },
        umap: {
          random_state: modelParams.umap.randomState,
        },
        hdbscan: {
          min_cluster_size: modelParams.hdbscan.minClusterSize,
        },
      },
    };

    const createModelRes = await axios
      .post("api/models", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer`,
        },
      })
      .then((res) => {
        if (res.data.message !== "success") return "error";
      })
      .catch((e) => {
        console.log("APIError", e);
        if (e.response.status === 401) {
          // logout();
        }
        return e.response;
      });
    if (createModelRes.status !== 200) return;

    // setLoaded(true);
    const res = "success" ? "success" : "error";

    return res;
  },

  setSelected: ({ dataSet, model }: any) => {
    console.log(dataSet, model);
  },
  deleteModel: (modelId: string) => {
    console.log(modelId);
  },
  loaded: false,
  setLoaded: (loaded: boolean) => {
    console.log(loaded);
  },
  setPlotAndLabels: (model: model) => {
    console.log(model);
  },
  selectedPlots: [
    {
      id: -1,
      coordinate: [0, 0],
      cluster_id: [0],
      text: "",
    },
  ],
  setSelectedPlots: (selectedPlots: plot[]) => {
    console.log(selectedPlots);
  },
  deleted: DeleteStatus.NONE,
  setDeleted: (deleted: DeleteStatus) => {
    console.log(deleted);
  },
  getModelNaive: async (dataSetId: string) => {
    const res = await axios.get("api/models", {
      params: { dataset_id: dataSetId },
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ""`,
      },
    });
  },
  getStatus: async () => {
    const res = await axios.get("api/status", {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  learningModel: [
    {
      dataset: "wikipedia",
      dataset_id: "moobaeS2",
      model: "trial2",
      model_id: "zahP9aex",
      created_at: "2020-02-07 14:00:00.000000+09:00",
      state: "pending",
    },
  ],
  openState: false,
  setOpenState: (openState: any): void => {},
};
