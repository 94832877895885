import React, { FC } from "react";
import Button from "@material-ui/core/Button";
import { Paper } from "@material-ui/core";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";

import { makeStyles, Theme, withStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";

import { Registration } from "../util/constant";
import { useGalaxy } from "../context/galaxy.context";
import { DeleteStatus } from "../util/constant";

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    position: "absolute",
    width: 300,
    boxShadow: theme.shadows[5],
    backgroundColor: theme.palette.background.default
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:focus": {
      outline: "none",
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)"
    }
  },
  header: {
    display: "table",
    height: 40,
    width: "100%",
    backgroundColor: theme.palette.divider,
    padding: "8px 20px",
    position: "relative",
    borderRadius: 0,
    boxShadow: theme.shadows[0]
  },
  closeButton: {
    display: "table-cell",
    position: "absolute",
    right: 10,
    top: "50%",
    transform: "translateY(-50%)"
  },
  title: {
    position: "absolute",
    fontSize: "20px",
    left: "1em",
    top: "50%",
    transform: "translateY(-50%)"
  },
  message: {
    color: theme.palette.text.primary,
    textAlign: "center",
    marginTop: "10px",
    marginBottom: "15px",
    fontSize: "1.2em"
  },
  content: {
    padding: "20px 20px"
  },
  button: {
    display: "inline-block",
    position: "absolute",
    right: "0.5em",
    top: "50%",
    transform: "translateY(-50%)",
    padding: 5
  }
}));

interface RegistrationModal {
  open: boolean;
  setOpen: (open: boolean) => void;
  RegistrationType: Registration;
  modelName?: string;
}

const DeleteModal: FC = () => {
  const classes = useStyles();
  const { deleted, setDeleted } = useGalaxy();

  return (
    <>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={classes.modal}
        open={deleted !== DeleteStatus.NONE ? true : false}
        closeAfterTransition={true}
        onClose={() => {
          setDeleted(DeleteStatus.NONE);
        }}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={deleted !== DeleteStatus.NONE ? true : false}>
          <Paper className={classes.paper}>
            <Paper className={classes.header} elevation={10} square={true}>
              <IconButton
                style={{ right: "1em" }}
                className={classes.button}
                onClick={() => {
                  setDeleted(DeleteStatus.NONE);
                }}
              >
                <ClearIcon fontSize={"small"} />
              </IconButton>
            </Paper>
            <Paper className={classes.content} elevation={1} square={false}>
              {deleted === DeleteStatus.SUCCUSS ? (
                <p className={classes.message}>削除しました</p>
              ) : (
                <p className={classes.message}>削除できませんでした</p>
              )}
            </Paper>
          </Paper>
        </Fade>
      </Modal>
    </>
  );
};

export default DeleteModal;
