import React from "react";

import { Route, Switch, Redirect } from "react-router-dom";

import Main from "./container/Main";
import Login from "./container/Login";
import Auth from "./container/Auth";
import { GalaxyProvider } from "./context/galaxy.context";
import { ToolsProvider } from "./context/tool.context";
import { UserProvider } from "./context/user.context";

import "./App.css";

const App = () => {
  return (
    <>
      <UserProvider>
        <Switch>
          <Route exact path="/login" component={Login} />
        </Switch>
        <Auth>
          <Switch>
            <GalaxyProvider>
              <ToolsProvider>
                <Route exact path="/" component={Main} />
                <Redirect to="/" />
              </ToolsProvider>
            </GalaxyProvider>
          </Switch>
        </Auth>
      </UserProvider>
    </>
  );
};

export default App;
