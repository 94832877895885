import React, { FC } from "react";
import { makeStyles, Theme, withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import ToggleButton from "@material-ui/lab/ToggleButton";
import html2canvas from "html2canvas";

// icons
import CameraAltOutlinedIcon from "@material-ui/icons/CameraAltOutlined";
import Close from "@material-ui/icons/Close";
import CropFree from "@material-ui/icons/CropFree";
import RadioButtonUncheckedOutlinedIcon from "@material-ui/icons/RadioButtonUncheckedOutlined";
import Brightness2OutlinedIcon from "@material-ui/icons/Brightness2Outlined";
import Tooltip from "@material-ui/core/Tooltip";

import { useTools } from "../context/tool.context";
import { useGalaxy } from "../context/galaxy.context";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    position: "absolute",
    zIndex: 100,
    right: 20,
    bottom: 32,
    borderRadius: "10px",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

const StyledToggleButton = withStyles((theme) => ({
  root: {
    margin: theme.spacing(0.3),
    border: "none",
    height: 40,
    padding: theme.spacing(1, 1),
  },
  label: {
    color: theme.palette.text.secondary,
  },
}))(ToggleButton);

interface ToolsProps {
  lasso: boolean;
  box: boolean;
  labelMode: boolean;
  nightMode: boolean;
}

const ToolBar: FC<ToolsProps> = ({ lasso, box, labelMode, nightMode }) => {
  const classes = useStyles();
  const { setNightMode, setLasso, setBox, setLabelMode } = useTools();
  const { setSelectedPlots } = useGalaxy();

  const handleToggleLasso = () => {
    if (!lasso) {
      setLasso(true);
      setBox(false);
    } else {
      setLasso(false);
    }
  };

  const handleToggleBox = () => {
    if (!box) {
      setBox(true);
      setLasso(false);
    } else {
      setBox(false);
      setSelectedPlots([]);
    }
  };

  const handleToggleLabel = () => {
    setLabelMode(!labelMode);
  };

  const handleNightMode = () => {
    setBox(false);
    setLasso(false);
    setSelectedPlots([]);
    setNightMode(!nightMode);
  };

  const getScreenShot = () => {
    const canvas_div = document.getElementById("canvas") as HTMLElement;

    html2canvas(canvas_div, {
      backgroundColor: "#F0F0F0",
      foreignObjectRendering: true,
    }).then(function (canvas) {
      const link = document.createElement("a");
      link.href = canvas.toDataURL();
      link.download = "screen shot.png";
      link.click();
    });
  };

  return (
    <Paper className={classes.root}>
      <Tooltip title="テキスト選択">
        <StyledToggleButton
          className={classes.iconButton}
          selected={box}
          value="select box"
          onClick={handleToggleBox}
        >
          <CropFree />
        </StyledToggleButton>
      </Tooltip>
      <Tooltip title="テキスト選択">
        <StyledToggleButton
          className={classes.iconButton}
          selected={lasso}
          value="select lasso"
          onClick={handleToggleLasso}
        >
          <RadioButtonUncheckedOutlinedIcon />
        </StyledToggleButton>
      </Tooltip>
      <Divider className={classes.divider} orientation="vertical" />
      <Tooltip title="ラベル非表示">
        <StyledToggleButton
          className={classes.iconButton}
          selected={!labelMode}
          value="toggle label"
          onClick={handleToggleLabel}
        >
          <Close />
        </StyledToggleButton>
      </Tooltip>
      <Tooltip title="スクリーンショット">
        <StyledToggleButton
          className={classes.iconButton}
          onClick={getScreenShot}
          value="screen shot"
          selected={false}
        >
          <CameraAltOutlinedIcon />
        </StyledToggleButton>
      </Tooltip>
      <Tooltip title="ナイトモード">
        <StyledToggleButton
          selected={nightMode}
          className={classes.iconButton}
          onClick={() => {
            handleNightMode();
          }}
          value="toggle night mode"
        >
          <Brightness2OutlinedIcon />
        </StyledToggleButton>
      </Tooltip>
    </Paper>
  );
};

export default ToolBar;
