import React, { FC, useState } from "react";

import { ThemeProvider } from "@material-ui/core/styles";
import { Paper, Button } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import { withRouter } from "react-router-dom";

import { backgroundColor } from "../util/config";

import "../App.css";
import { lightTheme } from "../util/config";
import { useUser } from "../context/user.context";

interface LoginProps {}

interface user {
  mailAddress: string | null;
  password: string | null;
  [key: string]: string | null;
}

const Login: FC<LoginProps> = () => {
  const [user, setUser] = useState<user>({ mailAddress: null, password: null });
  const [error, setError] = useState(false);
  const { login, isLoggedIn } = useUser();

  const theme = lightTheme;
  const styles: { root: React.CSSProperties } = {
    root: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      background: backgroundColor,
    },
  };

  const FIELDS = {
    mailAddress: "mailAddress",
    password: "password",
  };

  const onChangeData = (field: string) => (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const input = e.target as HTMLInputElement;
    setUser({
      ...user,
      [field]: input.value,
    });
  };

  const validation = () => {
    let res = true;
    for (let key in user) {
      if (user[key] === "") {
        res = false;
      }
    }
    return res;
  };

  const handleSubmit = async () => {
    console.log("button click");
    if (!validation()) {
      return;
    }
    if (!user.mailAddress || !user.password) {
      setError(true);
      return;
    }
    //ログイン成功
    const res = login({ ...user });
    res.then((res) => {
      // console.log("res", res);
      if (res === "User not found") {
        console.error("ログイン失敗, IDかeMailが間違っています");
        setError(true);
      } else if (res === "error") {
        console.error("ログイン失敗");
        setError(true);
      } else {
        setError(false);
      }
    });
    // if (res) {
    //   console.log(res);
    //   setError(false);
    // } else {
    //   //ログイン失敗
    //   console.log("error");
    //   setError(true);
    // }
    // });
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <div style={styles.root}>
          {/* <img
          src="/images/logo.png"
          style={{ width: 200, height: 40, marginBottom: 24 }}
        /> */}
          <h1>Doc Galaxy</h1>
          <Paper
            elevation={2}
            style={{ padding: 32, width: 320, boxSizing: "border-box" }}
          >
            {error && (
              <div
                style={{ color: "#f00", fontWeight: "bold", marginBottom: 8 }}
              >
                メールアドレスまたはパスワードが違います
              </div>
            )}
            <FormControl fullWidth={true}>
              <TextField
                id="mail-address"
                label="メールアドレス"
                type="text"
                // value={user.mailAddress}
                error={user.mailAddress === "" ? true : false}
                helperText={user.mailAddress === "" ? "必須" : ""}
                onClick={() => {
                  setUser({ ...user, mailAddress: "" });
                }}
                onChange={onChangeData(FIELDS.mailAddress)}
                style={{ width: "100%", height: 80 }}
              />
              <TextField
                id="password"
                label="パスワード"
                type="password"
                // value={user.password}
                error={user.password === "" ? true : false}
                helperText={user.password === "" ? "必須" : ""}
                onClick={() => {
                  setUser({ ...user, password: "" });
                }}
                onChange={onChangeData(FIELDS.password)}
                style={{ width: "100%", height: 80 }}
              />
            </FormControl>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              style={{ width: "100%", height: 50 }}
              onClick={handleSubmit}
            >
              ログイン
            </Button>
          </Paper>
          {/* <div style={{ marginTop: 12, textAlign: "center" }}> */}
          {/* <Link to="/" style={{ fontSize: 14 }}>
              パスワードを忘れた方はこちら
            </Link> */}
          {/* </div> */}
        </div>
      </ThemeProvider>
    </>
  );
};

export default withRouter(Login);
