import React, { FC } from "react";
import clsx from "clsx";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles
} from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import Paper from "@material-ui/core/Paper";
import {
  AutoSizer,
  Column,
  Table,
  TableCellRenderer,
  TableHeaderProps
} from "react-virtualized";
import Tooltip from "@material-ui/core/Tooltip";

declare module "@material-ui/core/styles/withStyles" {
  // Augment the BaseCSSProperties so that we can control jss-rtl
  interface BaseCSSProperties {
    /*
     * Used to control if the rule-set should be affected by rtl transformation
     */
    flip?: boolean;
  }
}

const styles = (theme: Theme) =>
  createStyles({
    flexContainer: {
      display: "flex",
      alignItems: "center",
      boxSizing: "border-box"
    },
    table: {
      // temporary right-to-left patch, waiting for
      // https://github.com/bvaughn/react-virtualized/issues/454
      "& .ReactVirtualized__Table__headerRow": {
        flip: false,
        paddingRight: theme.direction === "rtl" ? "0px !important" : undefined
      }
    },
    tableRow: {
      height: "auto",
      cursor: "pointer",
      "&:hover": {
        cursor: "pointer",
        backgroundColor:
          theme.palette.type === "light"
            ? theme.palette.grey[200]
            : theme.palette.grey[700]
      }
    },
    tableRowHover: {
      "&:hover": {
        backgroundColor: theme.palette.grey[200]
      }
    },
    tableCell: {
      flex: 1
    },
    noClick: {
      cursor: "initial"
    }
  });

const colorMap = [
  "#082844",
  "#004A66",
  "#00627E",
  "#007E68",
  "#2E6A57",
  "#616E2A"
];

interface ColumnData {
  dataKey: string;
  label: string;
  numeric?: boolean;
  width: number;
}

interface Row {
  index: number;
}

interface MuiVirtualizedTableProps extends WithStyles<typeof styles> {
  columns: ColumnData[];
  headerHeight?: number;
  onRowClick?: () => void;
  rowCount: number;
  rowGetter: (row: Row) => Data;
  rowHeight?: number;
}

class MuiVirtualizedTable extends React.PureComponent<
  MuiVirtualizedTableProps
> {
  static defaultProps = {
    headerHeight: 48,
    rowHeight: 48
  };

  cnt = 0;

  getRowClassName = ({ index }: Row) => {
    const { classes, onRowClick } = this.props;

    return clsx(classes.tableRow, classes.flexContainer, {
      [classes.tableRowHover]: index !== -1 && onRowClick != null
    });
  };

  cellRenderer: TableCellRenderer = ({ cellData, columnIndex }: any) => {
    const { classes, rowHeight, onRowClick } = this.props;

    return (
      <Tooltip
        title={columnIndex === 1 ? cellData : ""}
        placement="left"
        arrow
        enterDelay={0}
        leaveDelay={0}
      >
        <TableCell
          onDoubleClick={() => copyTextToClipboard(cellData)}
          component="div"
          className={clsx(classes.tableCell, classes.flexContainer, {
            [classes.noClick]: onRowClick == null
          })}
          variant="body"
          style={{
            cursor: "pointer",
            height: rowHeight,
            backgroundColor:
              columnIndex === 0
                ? cellData === -1
                  ? "#fff0"
                  : cellData
                : "none"
          }}
        >
          {columnIndex === 0
            ? ""
            : cellData.length >= 40
            ? cellData.slice(0, 40) + " ..."
            : cellData}
        </TableCell>
      </Tooltip>
    );
  };

  headerRenderer = ({
    label,
    columnIndex
  }: TableHeaderProps & { columnIndex: number }) => {
    const { headerHeight, classes } = this.props;

    return (
      <TableCell
        component="div"
        className={clsx(
          classes.tableCell,
          classes.flexContainer,
          classes.noClick
        )}
        // variant="span"
        style={{
          height: "0",
          paddingLeft: "0",
          fontWeight: "bold"
        }}
      >
        <span>{label}</span>
      </TableCell>
    );
  };

  render() {
    const {
      classes,
      columns,
      rowHeight,
      headerHeight,
      ...tableProps
    } = this.props;
    return (
      <AutoSizer>
        {({ height, width }: any) => (
          <Table
            height={height}
            width={width}
            rowHeight={rowHeight!}
            gridStyle={{
              direction: "inherit"
            }}
            headerHeight={0}
            className={classes.table}
            {...tableProps}
            rowClassName={this.getRowClassName}
          >
            {columns.map(({ dataKey, ...other }, index) => {
              return (
                <Column
                  key={dataKey}
                  headerRenderer={(headerProps: any) =>
                    this.headerRenderer({
                      ...headerProps,
                      columnIndex: index
                    })
                  }
                  className={classes.flexContainer}
                  cellRenderer={this.cellRenderer}
                  dataKey={dataKey}
                  {...other}
                />
              );
            })}
          </Table>
        )}
      </AutoSizer>
    );
  }
}

const VirtualizedTable = withStyles(styles)(MuiVirtualizedTable);

const copyTextToClipboard = (text: string) => {
  var copyFrom = document.createElement("textarea");
  copyFrom.textContent = text;

  var bodyElm = document.getElementsByTagName("body")[0];
  bodyElm.appendChild(copyFrom);

  copyFrom.select();
  document.execCommand("copy");
  bodyElm.removeChild(copyFrom);
  return;
};

// ------

interface Data {
  id: number;
  text: string;
  color: string;
}

interface ReactVirtualizedTableProps {
  selectedPlots: plot[];
}

const ReactVirtualizedTable: FC<ReactVirtualizedTableProps> = ({
  selectedPlots
}) => {
  const rows: Data[] = selectedPlots.map(plot => {
    return {
      id: plot.id,
      color: plot.color ? plot.color : "",
      text: plot.text
    };
  });

  return (
    <Paper style={{ height: 400, width: "100%" }}>
      <VirtualizedTable
        rowCount={rows.length}
        rowGetter={({ index }) => rows[index]}
        columns={[
          {
            width: 5,
            label: "",
            dataKey: "color"
          },
          {
            width: 395,
            label: "",
            dataKey: "text"
          }
        ]}
      />
    </Paper>
  );
};

export default ReactVirtualizedTable;
