/** @jsx jsx */
import { useRef, useEffect, useMemo, FC } from "react";
// eslint-disable-next-line
import { ReactThreeFiber, useFrame, useThree, extend } from "react-three-fiber";
import { jsx } from "@emotion/core";
import { Vector2 } from "three";

import { FXAAShader } from "three/examples/jsm/shaders/FXAAShader";

import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass";
import { AfterimagePass } from "three/examples/jsm/postprocessing/AfterimagePass";
// import { UnrealBloomPass } from "../jsm/postprocessing/UnrealBloomPass";
import { UnrealBloomPass } from "three/examples/jsm/postprocessing/UnrealBloomPass";

extend({
  EffectComposer,
  ShaderPass,
  RenderPass,
  AfterimagePass,
  UnrealBloomPass
});

declare global {
  namespace JSX {
    interface IntrinsicElements {
      effectComposer: ReactThreeFiber.Node<
        EffectComposer,
        typeof EffectComposer
      >;
      renderPass: ReactThreeFiber.Node<RenderPass, typeof RenderPass>;
      unrealBloomPass: ReactThreeFiber.Node<
        UnrealBloomPass,
        typeof UnrealBloomPass
      >;
      shaderPass: ReactThreeFiber.Node<ShaderPass, typeof ShaderPass>;
    }
  }
}

interface EffectProps {
  isRealMode: boolean;
}

const Effect: FC<EffectProps> = ({ isRealMode = true }) => {
  const composer = useRef<EffectComposer>();
  const { scene, gl, size, camera } = useThree();
  const aspect = useMemo(() => new Vector2(size.width, size.height), [size]);
  useEffect(() => void composer.current?.setSize(size.width, size.height), [
    size
  ]);
  useFrame(() => composer.current?.render(), 1);
  return (
    <effectComposer ref={composer} args={[gl]}>
      <renderPass attachArray="passes" scene={scene} camera={camera} />
      {/* 引数は, strength, radius, thretholdの順 */}
      {isRealMode ? (
        <unrealBloomPass attachArray="passes" args={[aspect, 1.3, 1.3, 0]} />
      ) : null}
      {/* いい感じにぼかしてくれる */}
      {isRealMode ? (
        <shaderPass
          attachArray="passes"
          args={[FXAAShader]}
          uniforms-resolution-value={[1 / size.width, 1 / size.height]}
          renderToScreen
        />
      ) : null}
    </effectComposer>
  );
};

export default Effect;
