import React, { FC, useState, useCallback, useEffect } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Tooltip from "@material-ui/core/Tooltip";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";

import { useGalaxy } from "../context/galaxy.context";
import { useTools } from "../context/tool.context";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: 350,
    position: "absolute",
    zIndex: 100,
    left: 20,
    top: 20,
    borderRadius: "10px",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

interface SearchBoxProps {
  setIsSideBarOpen: (isSideBarOpen: boolean) => void;
}

const SearchBox: FC<SearchBoxProps> = ({ setIsSideBarOpen }) => {
  const classes = useStyles();
  const {
    selected,
    loaded,
    setSelectedPlots,
    selectedPlots,
    setSelected,
  } = useGalaxy();
  const { setSearch } = useTools();
  const [text, setText] = useState("");

  useEffect(() => {
    setText("");
  }, [loaded]);

  const handleSubmit = (
    e: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (e.key === "Enter") {
      e.preventDefault();
      setSearch(true);
      submit();
    }
  };

  const submit = useCallback(() => {
    selected.plot.forEach((plot: plot) => {
      plot.searched = false;
    });
    let tmp;
    if (text.length === 0) {
      setSelectedPlots([]);
    } else {
      tmp = selected.plot.filter((plot: plot) => {
        return plot.text.indexOf(text) !== -1;
      });

      setSelectedPlots(tmp);
    }

    const pointIds = tmp === undefined ? [] : tmp.map((plot) => plot.id);
    // alert(pointIds);
    selected.plot.forEach((plot: plot) => {
      if (pointIds.indexOf(plot.id) !== -1) {
        plot.searched = true;
      }
    });

    setSelected({ ...selected, plot: selected.plot });
  }, [selected, setSelected, setSelectedPlots, text]);

  const handleInput = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const input = e.target as HTMLInputElement;
    setText(input.value);
  };

  const deleteText = useCallback(() => {
    setText("");
    // setSelectedPlots([]);
    setSearch(false);
  }, [setSearch]);

  return (
    <Paper component="form" className={classes.root}>
      <Tooltip title="サイドバーを開く" placement="right">
        <IconButton
          className={classes.iconButton}
          aria-label="menu"
          onClick={() => setIsSideBarOpen(true)}
        >
          <MenuIcon />
        </IconButton>
      </Tooltip>
      <InputBase
        value={text}
        className={classes.input}
        placeholder="Search Doc Galaxy"
        inputProps={{ "aria-label": "search google maps" }}
        onKeyDown={(e) => {
          handleSubmit(e);
        }}
        onChange={(e) => {
          handleInput(e);
        }}
      />
      {Boolean(text) ? (
        <Tooltip title="消去" placement="right">
          <IconButton
            className={classes.iconButton}
            aria-label="delete"
            onClick={() => {
              deleteText();
            }}
          >
            <ClearIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="検索する" placement="right">
          <IconButton className={classes.iconButton} aria-label="search">
            <SearchIcon />
          </IconButton>
        </Tooltip>
      )}
    </Paper>
  );
};

export default SearchBox;
