import React, { FC, useState, useEffect, useMemo, useCallback } from "react";

import { Paper } from "@material-ui/core";

import { makeStyles, Theme } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Tooltip from "@material-ui/core/Tooltip";

import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { useGalaxy } from "../context/galaxy.context";
import { useTools } from "../context/tool.context";
import VirtualizedTable from "./VirtualizedTable";
import CSV from "../util/savaCSV";
import { zf } from "../util/constant";

import "../App.css";
import "../container/Three.css";

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    position: "absolute",
    zIndex: 100,
    right: 20,
    top: 20,
    width: 400,
    maxHeight: 500,
    // boxShadow: theme.shadows[5],
    backgroundColor: theme.palette.background.default,
    borderRadius: 10,
  },
  header: {
    display: "table",
    height: 60,
    width: "100%",
    // backgroundColor: "#808080",
    backgroundColor: theme.palette.divider,
    padding: "8px 10px 8px 10px",
    position: "relative",
    borderRadius: "10px 10px 0px 0px",
    boxShadow: theme.shadows[1],
  },
  title: {
    position: "absolute",
    fontSize: "0.8em",
    fontWeight: "bold",
    left: "0.5em",
    paddingLeft: "1em",
    top: "50%",
    transform: "translateY(-50%)",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:focus": {
      outline: "none",
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
  button: {
    display: "inline-block",
    position: "absolute",
    right: "0.5em",
    top: "50%",
    transform: "translateY(-50%)",
    padding: 5,
  },
  card: {
    // backgroundColor: "#000"
  },
  cardSelected: {
    backgroundColor: "#EBEBEB",
  },
  cardSelectedNight: {
    backgroundColor: "#595959",
  },
  sliderContainer: {
    margin: "5px 0",
    padding: "0 5px",
  },
}));

const settings = {
  className: "left",
  arrows: false,
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerPadding: "60px",
  swipeToSlide: true,
  adaptiveHeight: true,
  variableWidth: true,
};

interface RegistrationModal {
  box: boolean;
  lasso: boolean;
  search: boolean;
}

interface PlotCnt {
  cluster_id: number;
  size: number;
}

const SelectedPlotTable: FC<RegistrationModal> = ({ box, lasso, search }) => {
  const { selectedPlots, selected } = useGalaxy();
  const { zoom, setSearch, nightMode } = useTools();
  const [open, setOpen] = useState(true);
  const [selectedCluster, setSelectedCluster] = useState(-2);
  const [displayPlots, setDisplayPlots] = useState(selectedPlots);

  const classes = useStyles();

  const nowLabels = useMemo(() => {
    return selected.labels
      .filter((label) => {
        return (
          label.range[0] <= zoom * zf + 3 &&
          (label.range[1] >= zoom * zf + 3 || label.range[1] === -1)
        );
      })
      .map((label) => label.cluster_id);
  }, [selected.labels, zoom]);

  const isNowLabel = useCallback(
    (cluster_id: number) => {
      return nowLabels.indexOf(cluster_id) !== -1 ? true : false;
    },
    [nowLabels]
  );

  const getColor = useCallback(
    (cluster_id: number) => {
      return selected.labels.filter((label: labels) => {
        return label.cluster_id === cluster_id;
      })[0];
    },
    [selected.labels]
  );

  useEffect(() => {
    if (selectedCluster !== -2) return;
    selectedPlots.forEach((plot: plot) => {
      const nowLabel = plot.cluster_id.filter((cluster_id) => {
        return isNowLabel(cluster_id);
      });
      if (nowLabel.length === 0) {
        plot.color = getColor(plot.cluster_id[0])?.color;
      } else {
        plot.color = getColor(nowLabel[0])?.color;
      }
    });
    setDisplayPlots(selectedPlots);
  }, [getColor, isNowLabel, selectedCluster, selectedPlots]);

  const handleCloseModal = () => {
    setOpen(false);
    setSearch(false);
  };

  const PlotCnt = useMemo(() => {
    if (!selectedPlots) return;
    const group = selectedPlots.reduce((result: PlotCnt[], current) => {
      const nowLabels = current.cluster_id.filter((cluster_id: number) => {
        return isNowLabel(cluster_id);
      });

      let element;
      if (nowLabels.length === 0) {
        element = result.find(
          (p: PlotCnt) => p.cluster_id === current.cluster_id[0]
        );
      } else {
        element = result.find((p: PlotCnt) => p.cluster_id === nowLabels[0]);
      }

      if (element) {
        element.size++; // count
      } else {
        result.push({
          cluster_id:
            nowLabels.length === 0 ? current.cluster_id[0] : nowLabels[0],
          size: 1,
        });
      }
      return result;
    }, []);

    group.sort(function (a, b) {
      if (a.size > b.size) return -1;
      if (a.size < b.size) return 1;
      return 0;
    });
    return group;
  }, [isNowLabel, selectedPlots]);

  useEffect(() => {
    setSelectedCluster(-2);
    setOpen(false);
    setOpen(box);
    if (selectedPlots) {
      if (selectedPlots.length === 0) {
        setOpen(false);
      } else {
        setOpen(true);
      }
    }
  }, [box, selectedPlots]);

  const handleClick = (cluster_id: number) => {
    if (cluster_id === selectedCluster) {
      setDisplayPlots(selectedPlots);
      setSelectedCluster(-2);
    } else {
      const tmp = selectedPlots.filter((plot) => {
        // 親クラスタの場合
        const nowLabel = plot.cluster_id.filter((cluster_id) => {
          return isNowLabel(cluster_id);
        });

        if (nowLabel.length === 0) {
          // 親だけ
          return plot.cluster_id.indexOf(cluster_id) === 0;
        } else if (nowLabel[0] !== cluster_id) {
          return false;
        } else {
          // 子だけ
          return plot.cluster_id.indexOf(cluster_id) !== -1;
        }
      });

      setDisplayPlots(tmp);
      setSelectedCluster(cluster_id);
    }
  };

  const saveCSV = () => {
    if (selected.labels === undefined) return;
    const labels = selected.labels;
    const output = displayPlots.map((plot, idx) => {
      const nowLabel = plot.cluster_id.filter((cluster_id) => {
        return isNowLabel(cluster_id);
      });
      let label;
      if (nowLabel.length === 0) {
        label = labels.find((label) => {
          return label.cluster_id === plot.cluster_id[0];
        });
      } else {
        label = labels.find((label) => {
          return label.cluster_id === nowLabel[0];
        });
      }

      return {
        id: idx,
        cluster_id: label?.cluster_id,
        "top word": label ? label.words[0] : "-",
        "second word": label ? label.words[1] : "-",
        "third word": label ? label.words[2] : "-",
        text: plot.text,
      };
    });
    new CSV(output).save("selected_plots.csv");
  };

  const getLabel = (cluster_id: number) => {
    return selected.labels.filter((label: labels) => {
      return cluster_id === label.cluster_id;
    })[0];
  };

  return (
    <>
      {selected && (box || lasso || search) && open && displayPlots && (
        <Paper className={classes.paper}>
          <Paper className={classes.header} elevation={10}>
            <span className={classes.title}>
              {`Selected  ${selectedPlots.length} Points`}
            </span>
            <Tooltip title="csvに書き出す">
              <IconButton
                style={{ right: "2.5em" }}
                className={classes.button}
                onClick={saveCSV}
              >
                <SaveAltIcon fontSize={"small"} />
              </IconButton>
            </Tooltip>
            <Tooltip title="閉じる">
              <IconButton
                style={{ right: "1em" }}
                className={classes.button}
                onClick={handleCloseModal}
              >
                <ClearIcon fontSize={"small"} />
              </IconButton>
            </Tooltip>
          </Paper>
          <div className={classes.sliderContainer}>
            <Slider {...settings}>
              {selectedPlots.length !== 0 &&
                PlotCnt?.map((cluster, idx) => (
                  <div key={idx}>
                    <Card
                      className={
                        cluster.cluster_id === selectedCluster
                          ? nightMode
                            ? classes.cardSelectedNight
                            : classes.cardSelected
                          : classes.card
                      }
                    >
                      <CardActionArea
                        onDoubleClick={(e) => {
                          handleClick(cluster.cluster_id);
                        }}
                      >
                        <CardContent
                          style={{
                            display: "flex",
                            lineHeight: "1em",
                            padding: "5px",
                          }}
                        >
                          {/* <div className={"containerStyle"}> */}
                          <span
                            style={{
                              backgroundColor: `hsl(${
                                getLabel(cluster.cluster_id)?.hue % 360
                              },50%,30%)`,
                            }}
                            className={"sizeStyle"}
                          >
                            {cluster.size}
                          </span>
                          <div className={"clusterStyle"}>
                            <p
                              className={nightMode ? "topWordNight" : "topWord"}
                            >
                              {getLabel(cluster.cluster_id)?.words[0]}
                            </p>
                            <span
                              className={
                                nightMode ? "otherWordsNight" : "otherWords"
                              }
                            >
                              {getLabel(cluster.cluster_id)?.words[1]}{" "}
                              {getLabel(cluster.cluster_id)?.words[2]}
                            </span>
                          </div>
                          {/* </div> */}
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </div>
                ))}
            </Slider>
          </div>
          <VirtualizedTable selectedPlots={displayPlots} />
        </Paper>
      )}
    </>
  );
};

export default SelectedPlotTable;
