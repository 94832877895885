import React, {
  useState,
  useEffect,
  useContext,
  createContext,
  useCallback,
  FC,
} from "react";
import axios from "axios";
import { BASE_URL } from "../util/config";
import { useUser } from "./user.context";
import { DeleteStatus, initialValue } from "../util/constant";

const formatDataSets = (dataSets: any[]) => {
  const tmp = [];
  for (let dataSet of dataSets) {
    tmp.push({
      dataSetName: dataSet["dataset"],
      dataSetId: dataSet["dataset_id"],
      createdAt: dataSet["created_at"],
    });
  }
  return tmp;
};

export const formatModels = (models: any[]) => {
  const tmp = [];
  for (let model of models) {
    tmp.push({
      modelName: model["model"],
      modelId: model["model_id"],
      createdAt: model["created_at"],
      state: model["state"],
    });
  }
  return tmp;
};

const initColor = "#fff";

export const GalaxyContext = createContext(initialValue);

const initLabels = (labels: labels[]) => {
  labels.forEach((label: labels) => {
    if (label.cluster_id === label.size) {
      label.color = initColor;
      label.hue = 0;
      label.saturation = 50;
      label.lightness = 75;
    } else {
      label.color = "";
      label.hue = 0;
      label.saturation = 0;
      label.lightness = 0;
    }
  });
};

// Provider -------------------------------------------------------------------
export const GalaxyProvider: FC = ({ children }) => {
  const [dataSets, setDataSets] = useState<dataSet[]>([]);
  const [models, setModels] = useState<model[]>([]);
  const [selected, setSelected] = useState<selected>(initialValue.selected);
  const [loaded, setLoaded] = useState(false);
  const [deleted, setDeleted] = useState(DeleteStatus.NONE);
  const [selectedPlots, setSelectedPlots] = useState<plot[]>([]);
  const [learningModel, setLearningModel] = useState<state[]>([]);
  const [openState, setOpenState] = useState(false);
  const { token, logout } = useUser();

  // dataset --------------------------------------------------------------

  const deleteDataSet = async (dataSetId: string) => {
    const res = await axios.delete(BASE_URL + "api/dataset/" + dataSetId, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token}`,
      },
    });

    if (res.status === 401) {
      logout();
      return;
    }

    if (res.data.deleted.length === 0) {
      setDeleted(DeleteStatus.ERROR);
      return;
    } else {
      setDeleted(DeleteStatus.SUCCUSS);
    }

    console.log("datasetDeleted", res);

    const otherDataSets = dataSets.filter((dataSet) => {
      return dataSet.dataSetId !== dataSetId;
    });
    setDataSets(otherDataSets);
    getModels(otherDataSets[0].dataSetId);
    setSelected({
      dataSet: otherDataSets[0],
      model: models[0],
      plot: initialValue.selected.plot,
      labels: initialValue.selected.labels,
    });
  };

  const uploadDataSet = async ({ dataSetName, csvFile }: uploadDataSet) => {
    const data = new FormData();
    data.append("csvfile", csvFile);
    data.append("dataset", dataSetName);

    const uploadDataSetRes = await axios
      .post(BASE_URL + "api/dataset", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
        },
      })
      .catch((e) => {
        console.log("APIError", e);
        if (e.response.status === 401) {
          logout();
        }
        return e.response;
      });
    if (uploadDataSetRes.status !== 200) return;
    if (uploadDataSetRes.data.message !== "success") return;
    console.log("uploadDataSetRes", uploadDataSetRes);
    const dataSetRes = await axios
      .get(BASE_URL + "api/dataset", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
        },
      })
      .catch((e) => {
        console.log("APIError", e);
        if (e.response.status === 401) {
          logout();
        }
        return e.response;
      });
    if (dataSetRes.status !== 200) return;

    const dataSets = formatDataSets(dataSetRes.data);
    setDataSets(dataSets);

    const dataSetId = dataSetRes.data.filter((dataSet: dataSetAPI) => {
      return dataSet.dataset === dataSetName;
    })[0].dataset_id;

    setSelected({
      ...selected,
      dataSet: {
        dataSetId: dataSetId,
        dataSetName: dataSetName,
        createdAt: "",
      },
    });
    return dataSetId;
  };

  //  model--------------------------------------------------

  const getModelNaive = async (dataSetId: string) => {
    const res = await axios
      .get(BASE_URL + "api/models", {
        params: { dataset_id: dataSetId },
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
        },
      })
      .catch((e) => {
        console.log("APIError", e);
        if (e.response.status === 401) {
          logout();
        }
        return e.response;
      });
    if (res.status !== 200) return;

    if (res.data.length === 0) {
      console.log("res", res.data);
    } else {
      console.log("res", res.data);
      const models = formatModels(res.data);
      setModels(models);
    }
  };

  const getModels = async (dataSetId: string) => {
    // モデルを取得してselectedにセットする関数
    setLoaded(false);
    setSelected({ ...selected });
    const res = await axios
      .get(BASE_URL + "api/models", {
        params: { dataset_id: dataSetId },
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
        },
      })
      .catch((e) => {
        console.log("APIError", e);
        if (e.response.status === 401) {
          logout();
        }
        return e.response;
      });
    if (res.status !== 200) return;

    // モデルがなかった場合
    if (res.data.length === 0) {
      setSelected({
        ...selected,
        model: {
          modelId: "notFound",
          modelName: "",
          createdAt: "",
          state: "",
        },
      });
    } else {
      const models = formatModels(res.data);
      setModels(models);
      const plotRes = await axios
        .post(
          BASE_URL + "api/results/plot",
          { model_id: models[0].modelId },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `bearer ${token}`,
            },
          }
        )
        .catch((e) => {
          console.log("APIError", e);
          if (e.response.status === 401) {
            logout();
          }
          return e.response;
        });
      if (plotRes.status !== 200) return;

      const labelsRes = await axios
        .post(
          BASE_URL + "api/results/label",
          { model_id: models[0].modelId },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `bearer ${token}`,
            },
          }
        )
        .catch((e) => {
          console.log("APIError", e);
          if (e.response.status === 401) {
            logout();
          }
          return e.response;
        });
      if (labelsRes.status !== 200) return;

      const labels = labelsRes.data;

      labels.forEach((label: labels) => {
        if (label.cluster_id === label.size) {
          label.color = initColor;
          label.hue = 0;
          label.saturation = 50;
          label.lightness = 75;
        } else {
          label.color = "";
          label.hue = 0;
          label.saturation = 0;
          label.lightness = 0;
        }
      });

      setSelected({
        dataSet: dataSets.filter((dataSet) => {
          return dataSet.dataSetId === dataSetId;
        })[0],
        model: models[0],
        // userId: userId,
        plot: plotRes.data,
        labels: labelsRes.data,
      });
    }
    setLoaded(true);
  };

  const createModel = async ({
    dataSetId,
    modelName,
    modelParams,
  }: createModel) => {
    const data = {
      dataset_id: dataSetId,
      model: modelName,
      model_params: {
        flow: modelParams.flow,
        doc2vec: {
          seed: modelParams.doc2vec.seed,
          vector_size: modelParams.doc2vec.vectorDim,
          epochs: modelParams.doc2vec.epoch,
        },
        umap: {
          random_state: modelParams.umap.randomState,
        },
        hdbscan: {
          min_cluster_size: modelParams.hdbscan.minClusterSize,
        },
      },
    };

    const createModelRes = await axios
      .post(BASE_URL + "api/models", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
        },
      })
      .catch((e) => {
        console.log("APIError", e);
        if (e.response.status === 401) {
          logout();
        }
        return e.response;
      });
    if (createModelRes.status !== 200) return;

    setLoaded(true);

    console.log("createModelRes", createModelRes);

    if (createModelRes.data.message !== "start") return "error";

    return "success";
  };

  const deleteModel = async (modelId: string) => {
    const res = await axios
      .delete(BASE_URL + "api/models/" + modelId, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
        },
      })
      .catch((e) => {
        console.log("APIError", e);
        if (e.response.status === 401) {
          logout();
        }
        return e.response;
      });
    if (res.status !== 200) return;

    if (res.data.deleted.length === 0) {
      setDeleted(DeleteStatus.ERROR);
      return;
    } else {
      setDeleted(DeleteStatus.SUCCUSS);
    }

    const otherModels = models.filter((model) => {
      return model.modelId !== modelId;
    });

    if (otherModels.length === 0) {
      const otherDataSet = dataSets.filter((dataSet) => {
        return dataSet.dataSetId !== selected.dataSet.dataSetId;
      })[0];

      const res = await axios
        .get(BASE_URL + "api/models", {
          params: { dataset_id: otherDataSet.dataSetId },
        })
        .catch((e) => {
          console.log("APIError", e);
          if (e.response.status === 401) {
            logout();
          }
          return e.response;
        });
      if (res.status !== 200) return;

      const nextModels = formatModels(res.data);
      const model = nextModels[0];
      setModels(nextModels);
      setSelected({
        dataSet: otherDataSet,
        model: model,
        plot: initialValue.selected.plot,
        labels: initialValue.selected.labels,
      });
    } else {
      setModels(otherModels);
      setSelected({
        ...selected,
        model: otherModels[0],
      });
    }
  };
  // other ------------------------------------------------
  //　プロット情報とラベル情報を取得して、selectedにセットする
  const setPlotAndLabels = useCallback(
    async (model: model) => {
      setLoaded(false);
      setSelectedPlots([]);
      const plotRes = await axios
        .post(
          BASE_URL + "api/results/plot",
          { model_id: model.modelId },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `bearer ${token}`,
            },
          }
        )
        .catch((e) => {
          console.log("APIError", e);
          if (e.response.status === 401) {
            logout();
          }
          return e.response;
        });
      if (plotRes.status !== 200) return;

      const labelsRes = await axios
        .post(
          BASE_URL + "api/results/label",
          { model_id: model.modelId },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `bearer ${token}`,
            },
          }
        )
        .catch((e) => {
          console.log("APIError", e);
          if (e.response.status === 401) {
            logout();
          }
          return e.response;
        });
      if (labelsRes.status !== 200) return;

      const labels = labelsRes.data;

      initLabels(labels);

      setSelected({
        ...selected,
        model: model,
        plot: plotRes.data,
        labels: labels,
      });
      setLoaded(true);
    },
    [logout, selected, token]
  );

  const getStatus = useCallback(async () => {
    await axios
      .get(BASE_URL + "api/states", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => res.data)
      .then((data) => {
        if (data === 0) return;
        const tmp = data.filter((model: model) => {
          return model.state !== "done";
        });

        setLearningModel((prev) => {
          if (prev.length - tmp.length === 1) {
            setOpenState(true);
          }
          return tmp;
        });
      })
      .catch((e) => {
        if (e.response.status === 401) {
          logout();
        }
      });
  }, [logout, token]);

  // 初期化 --------------------------------------
  useEffect(() => {
    const getInitialData = async () => {
      setLoaded(false);
      if (token === "0") return;
      const dataSetRes = await axios
        .get(BASE_URL + "api/dataset", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${token}`,
          },
        })
        .catch((e) => {
          console.log("APIError", e);
          if (e.response.status === 401) {
            logout();
          }
          return e.response;
        });

      if (dataSetRes.status !== 200) return;

      if (dataSetRes.data.length === 0) {
        setSelected({ ...initialValue.selected });
        setLoaded(true);
        return;
      }
      const dataSets = formatDataSets(dataSetRes.data);
      const modelRes = await axios
        .get(BASE_URL + "api/models", {
          params: { dataset_id: dataSets[0].dataSetId },
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${token}`,
          },
        })
        .catch((e) => {
          console.log("APIError", e);
          if (e.response.status === 401) {
            logout();
          }
          return e.response;
        });

      if (modelRes.status !== 200) return;

      const models = formatModels(modelRes.data);

      const plotRes = await axios
        .post(
          BASE_URL + "api/results/plot",
          { model_id: models[0].modelId },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `bearer ${token}`,
            },
          }
        )
        .catch((e) => {
          console.log("APIError", e);
          if (e.response.status === 401) {
            logout();
          }
          return e.response;
        });
      if (plotRes.status !== 200) return;

      const labelsRes = await axios
        .post(
          BASE_URL + "api/results/label",
          { model_id: models[0].modelId },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `bearer ${token}`,
            },
          }
        )
        .catch((e) => {
          console.log("APIError", e);
          if (e.response.status === 401) {
            logout();
          }
          return e.response;
        });
      if (labelsRes.status !== 200) return;

      const labels = labelsRes.data;

      initLabels(labels);

      setDataSets(dataSets);
      setModels(models);
      setSelected({
        dataSet: dataSets[0],
        model: models[0],
        plot: plotRes.data,
        labels: labels,
      });
      getStatus();
      setLoaded(true);
    };
    getInitialData();
  }, [getStatus, logout, token]);

  // --------------------------------

  const value = {
    dataSets,
    models,
    selected,
    getModels,
    setSelected,
    deleteDataSet,
    uploadDataSet,
    createModel,
    deleteModel,
    loaded,
    setLoaded,
    setPlotAndLabels,
    selectedPlots,
    setSelectedPlots,
    deleted,
    setDeleted,
    getModelNaive,
    getStatus,
    learningModel,
    openState,
    setOpenState,
  };
  return <GalaxyContext.Provider value={value} children={children} />;
};
export function useGalaxy() {
  return useContext(GalaxyContext);
}
