import React, { FC, useState, useEffect } from "react";
import { useSpring, animated } from "react-spring";
import {
  makeStyles,
  createStyles,
  Theme,
  withStyles,
} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";
import Skeleton from "@material-ui/lab/Skeleton";

import TimelineIcon from "@material-ui/icons/Timeline";
import AddIcon from "@material-ui/icons/Add";
import ClearIcon from "@material-ui/icons/Clear";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { IoMdArrowDropleft } from "react-icons/io";
import { IoMdArrowDropright } from "react-icons/io";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import Tooltip from "@material-ui/core/Tooltip";

import RegistrationModal from "../components/RegistrationModal";
import DeleteStatusModal from "./DeleteStatusModal";
import DeleteModal from "./DeleteModal";
import { Registration } from "../util/constant";
import { useGalaxy } from "../context/galaxy.context";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: "2px 4px",
      display: "flex",
      alignItems: "center",
      width: 350,
      position: "absolute",
      zIndex: 100,
      left: 20,
      top: 80,
      borderRadius: "10px",
    },
    list: {
      width: "100%",
      backgroundColor: theme.palette.background.paper,
    },
    nested: {
      paddingLeft: theme.spacing(2),
    },
    iconButton: {
      position: "absolute",
      right: 0,
      padding: 10,
    },
    model: {
      paddingTop: 2,
      paddingBottom: 12,
    },
    expansionIcon: {
      position: "absolute",
      left: "47%",
      bottom: 0,
      padding: 5,
      textAlign: "center",
    },
    expansionLessButton: {
      textAlign: "center",
    },
    subheader: {
      display: "inline-block",
      marginTop: "0.5em",
      lineHeight: 2,
      verticalAlign: "middle",
    },
    hideButton: {
      width: 20,
      height: 40,
      position: "absolute",
      top: 7,
      left: "100%",
      backgroundColor: theme.palette.background.paper,
      cursor: "pointer",

      textAlign: "center",
      verticalAlign: "middle",
    },
    hideIcon: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translateY(-50%) translateX(-50%)",
      width: 15,
      height: 30,
    },
  })
);

const AnimatedPaper = animated(Paper);

const ExpandLessButton = withStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 24,
    fontWeight: "bold",
    color: "white",
  },
  startIcon: {
    color: "white",
  },
}))(Button);

interface ModelBoxProps {
  isNightMode: boolean;
}

const ModelBox: FC<ModelBoxProps> = ({ isNightMode }) => {
  const classes = useStyles();
  let { models, selected, loaded, setPlotAndLabels } = useGalaxy();

  const [openCollapse, setOpenCollapse] = useState(false);
  const [openRegistrationModal, setRegistrationModal] = useState(false);
  const [openDeleteModal, setDeleteModal] = useState(false);
  const [hide, setHide] = useState(false);

  useEffect(() => {
    if (selected && selected.model.modelId === "notFound") {
      setRegistrationModal(true);
    }
  }, [selected]);

  const spring = useSpring({
    left: hide ? -350 : 20,
    config: {
      tension: 1800,
      friction: 100,
      precision: 1,
      clamp: true,
    },
  });
  const registrationType = Registration.Model;
  const handleOpenCollapse = () => {
    setOpenCollapse(true);
  };

  const handleCloseCollapse = () => {
    setOpenCollapse(false);
  };

  const handleOpenRegModal = () => {
    setRegistrationModal(true);
  };

  const handleOpenDelModal = () => {
    setDeleteModal(true);
  };

  const handleToggleHidden = () => {
    setHide(!hide);
  };

  const handleChangeModel = (model: model) => {
    setPlotAndLabels(model);
    setOpenCollapse(false);
  };

  useEffect(() => {
    if (!loaded) setOpenCollapse(false);
  }, [loaded]);

  return (
    <>
      {selected && (
        <>
          <AnimatedPaper className={classes.root} style={spring}>
            <List
              component="nav"
              aria-labelledby="nested-list-subheader"
              subheader={
                <ListSubheader
                  component="div"
                  id="nested-list-subheader"
                  className={classes.subheader}
                >
                  {selected.dataSet.dataSetName ? (
                    selected.dataSet.dataSetName
                  ) : (
                    <Skeleton width={100} />
                  )}
                </ListSubheader>
              }
              className={classes.list}
            >
              <ListItem className={classes.model}>
                <ListItemIcon>
                  <TimelineIcon />
                </ListItemIcon>
                {selected.model.modelName ? (
                  <ListItemText primary={selected.model.modelName} />
                ) : (
                  <Skeleton width={220} height={"2em"} />
                )}

                <Tooltip title="モデルを削除する">
                  <IconButton
                    className={classes.iconButton}
                    size={"small"}
                    onClick={handleOpenDelModal}
                  >
                    <ClearIcon />
                  </IconButton>
                </Tooltip>
              </ListItem>
              <Collapse in={openCollapse} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <Divider />
                  {models
                    .filter((model) => {
                      return model.modelId !== selected.model.modelId;
                    })
                    .map((model, idx) => (
                      <ListItem
                        disabled={model.state !== "done"}
                        button
                        className={classes.nested}
                        onClick={(e) => handleChangeModel(model)}
                        key={idx}
                      >
                        <ListItemIcon>
                          {model.state === "done" ? (
                            <TimelineIcon />
                          ) : (
                            <HourglassEmptyIcon />
                          )}
                        </ListItemIcon>
                        <ListItemText primary={model.modelName} />
                      </ListItem>
                    ))}
                  <Divider />
                  <ListItem button className={classes.nested}>
                    <ListItemIcon>
                      <AddIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary="Add New Model"
                      onClick={handleOpenRegModal}
                    />
                  </ListItem>
                  <div className={classes.expansionLessButton}>
                    <ExpandLessButton
                      onClick={handleCloseCollapse}
                      variant="contained"
                      color="primary"
                      startIcon={<ExpandLessIcon />}
                    >
                      <em>すべて閉じる</em>
                    </ExpandLessButton>
                  </div>
                </List>
              </Collapse>
            </List>
            <Fade in={!openCollapse}>
              <Tooltip title="パネルを開く">
                <IconButton
                  className={classes.expansionIcon}
                  onClick={handleOpenCollapse}
                >
                  <ExpandMoreIcon />
                </IconButton>
              </Tooltip>
            </Fade>
            <Tooltip title="パネルを折りたたむ" placement="right">
              <div
                style={{
                  borderLeft: isNightMode
                    ? "1px solid #000000"
                    : "1px solid #D4D4D4",
                }}
                className={classes.hideButton}
                onClick={handleToggleHidden}
              >
                {hide ? (
                  <IoMdArrowDropright
                    fontSize="small"
                    className={classes.hideIcon}
                  />
                ) : (
                  <IoMdArrowDropleft
                    fontSize="small"
                    className={classes.hideIcon}
                  />
                )}
              </div>
            </Tooltip>
          </AnimatedPaper>
        </>
      )}
      {selected && selected.dataSet && (
        <>
          <RegistrationModal
            open={openRegistrationModal}
            setOpen={setRegistrationModal}
            registrationType={registrationType}
            dataSetName={selected.dataSet.dataSetName}
          />
          <DeleteModal
            open={openDeleteModal}
            setOpen={setDeleteModal}
            RegistrationType={registrationType}
            modelName={selected.model.modelName}
          />
          <DeleteStatusModal />
        </>
      )}
    </>
  );
};

export default ModelBox;
