import React, { useRef, useEffect } from "react";
// eslint-disable-next-line
import { useFrame, useThree, extend } from "react-three-fiber";
import { MOUSE } from "three";

import { OrbitControls } from "../jsm/controls/OrbitControls.js";

extend({ OrbitControls });

const Controls = ({ isControl, lasso, box, zoom, setZoom, loaded }) => {
  const controlsRef = useRef();
  const pan = useRef(false);
  const altKey = useRef(false);
  const stopFrames = useRef(0);
  const { camera, gl } = useThree();
  const preZoom = useRef(0);
  const canvas = gl.domElement;

  useFrame(() => {
    canvas.addEventListener("mousedown", (e) => {
      altKey.current = e.altKey;
      pan.current = true;
    });
    canvas.addEventListener("mousemove", (e) => {
      if (pan.current) canvas.style.cursor = "move";
      altKey.current = e.altKey;
    });
    canvas.addEventListener("mouseup", () => {
      canvas.style.cursor = "default";
      pan.current = false;
    });
    controlsRef.current.enablePan = !(lasso ^ box) || altKey.current;
  });

  // マウスホイールを利用してズームした場合に、stateのzoomにカメラのzoomを一致させる。
  useFrame(() => {
    if (preZoom.current === camera.zoom) {
      stopFrames.current += 1;
    } else {
      stopFrames.current = 0;
    }
    if (stopFrames.current === 2) {
      setZoom(camera.zoom);
      stopFrames.current = 0;
    }
    preZoom.current = camera.zoom;
  });

  // ツールボタンのzoomをカメラのズームに一致させる。
  useEffect(() => {
    camera.zoom = zoom;
  }, [camera.zoom, zoom]);

  useEffect(() => {
    controlsRef.current.reset();
  }, []);

  return (
    <orbitControls
      ref={controlsRef}
      args={[camera, document.getElementById("canvas")]}
      position0={[0, 0, 15]}
      zoom0={1}
      enableDamping={false}
      enabled={isControl}
      enableZoom={true}
      zoomSpeed={1.0}
      maxZoom={100}
      minZoom={0.5}
      enableRotate={false}
      autoRotate={false}
      rotateSpeed={1.0}
      enablePan={false}
      panSpeed={1.0}
      minDistance={0}
      maxDistance={Infinity}
      minPolarAngle={0}
      maxPolarAngle={Math.PI}
      screenSpacePanning={true}
      mouseButtons={{
        LEFT: MOUSE.PAN,
        MIDDLE: MOUSE.DOLLY,
        RIGHT: MOUSE.ROTATE,
      }}
      enableKeys={false}
    />
  );
};

export default Controls;
