import React, {
  useState,
  useContext,
  createContext,
  useEffect,
  useCallback,
  FC,
} from "react";
import axios from "axios";

import { BASE_URL } from "../util/config";

interface loginArgs {
  mailAddress: string | null;
  password: string | null;
}

export const UserContext = createContext({
  login: async ({ mailAddress, password }: loginArgs) => {
    const res = await axios.post(
      BASE_URL + "api/users/login",
      { user: mailAddress, password: password },
      {
        headers: {
          ContentType: "application/json",
        },
      }
    );

    return res.data.message;
  },
  logout: () => {},
  isLoggedIn: "0",
  token: "0",
});

export const UserProvider: FC = ({ children }) => {
  const getLocalStorage = (key: string) => {
    const ret = localStorage.getItem(key);
    if (ret) {
      return ret;
    }
    return "0";
  };

  const getIsLoggedIn = () => getLocalStorage("isLoggedIn");
  const setIsLoggedIn = () => localStorage.setItem("isLoggedIn", "1");
  const setIsLoggedOut = () => localStorage.setItem("isLoggedIn", "0");
  const getToken = () => getLocalStorage("token");
  const setTokenLocal = (token: string) => localStorage.setItem("token", token);
  const removeToken = () => localStorage.removeItem("token");

  const [isLoggedIn, setLogin] = useState(getIsLoggedIn());
  const [token, setToken] = useState(getToken());

  const login = async ({ mailAddress, password }: loginArgs) => {
    const res = await axios.post(
      BASE_URL + "api/users/login",
      { user: mailAddress, password: password },
      {
        headers: {
          ContentType: "application/json",
        },
      }
    );

    if (res.data.message === "User not found") {
      return res.data.message;
    }
    console.log(res);
    if (res.status !== 200) {
      console.log("error", res);
      return "error";
    }
    setIsLoggedIn();
    setLogin("1");
    setTokenLocal(res.data.token);
    setToken(res.data.token);

    return res.data.message;
  };
  const logout = useCallback(async () => {
    removeToken();
    // setToken("");
    setIsLoggedOut();
    setLogin("0");
  }, []);

  useEffect(() => {
    if (isLoggedIn === "1") {
      console.log("ログイン済み", isLoggedIn);
      setLogin("1");
    } else {
      console.log("未ログイン", isLoggedIn);
      setIsLoggedOut();
      setLogin("0");
      // logout();
    }
    return () => {};
  }, [isLoggedIn, logout]);

  const value = {
    login,
    logout,
    isLoggedIn,
    token,
  };

  return <UserContext.Provider value={value} children={children} />;
};
export function useUser() {
  return useContext(UserContext);
}
