import React, { useRef, useState, useEffect, useCallback } from "react";
import { useThree, extend } from "react-three-fiber";
import * as meshline from "threejs-meshline";
import { Vector3, Shape } from "three";

extend(meshline);

// クリッピング座標をワールド座標に変換
export const clippingToWorld = (x, y, camera, z = 1) => {
  let vector = new Vector3(x, y, z);
  vector.unproject(camera);
  return vector;
};

const LassoSelection = ({ lasso, nightMode, dataSet, setSelectedPlots }) => {
  const { mouse, camera, gl } = useThree();
  const [shape, setShape] = useState(new Shape());

  const vertices = useRef([]);
  const start = useRef();
  const isSelectMode = useRef(false);
  const tmpShape = useRef();
  const canvas = gl.domElement;

  let data = dataSet.flat();

  const selectedPoints = useCallback(
    sides => {
      const selectedPoints = [];
      for (let plot of data) {
        let xCnt = 0;
        // let yCnt = 0;
        for (let side of sides) {
          // y座標は入っているか？
          if (
            (plot.coordinate[1] * 100 >= side.v1.y &&
              plot.coordinate[1] * 100 < side.v2.y) ||
            (plot.coordinate[1] * 100 >= side.v2.y &&
              plot.coordinate[1] * 100 < side.v1.y)
          ) {
            if (plot.coordinate[0] * 100 <= Math.max(side.v1.x, side.v2.x)) {
              xCnt += 1;
            }
          }
        }
        if (xCnt % 2 === 1) selectedPoints.push(plot);
      }
      if (selectedPoints.length === 0) return;
      setSelectedPlots([]);
      setSelectedPlots(selectedPoints);
      return selectedPoints;
    },
    [data, setSelectedPlots]
  );

  useEffect(() => {
    setShape(new Shape());
    vertices.current.length = 0;
  }, [lasso]);

  const fStart = event => {
    if (event.altKey) return;
    if (!isSelectMode.current) {
      isSelectMode.current = true;
      tmpShape.current = new Shape();
      start.current = clippingToWorld(mouse.x, mouse.y, camera);
      tmpShape.current.moveTo(start.current.x, start.current.y);
    }
  };

  const fPress = () => {
    if (isSelectMode.current) {
      canvas.style.cursor = "default";
      let vector = clippingToWorld(mouse.x, mouse.y, camera);
      tmpShape.current.lineTo(vector.x, vector.y);
    }
  };

  const fEnd = () => {
    if (isSelectMode.current) {
      isSelectMode.current = false;
      tmpShape.current.lineTo(start.current.x, start.current.y);
      setShape(tmpShape.current);
      selectedPoints(tmpShape.current.curves);
    }
  };

  useEffect(() => {
    document
      .getElementById("three")
      .addEventListener("mousedown", fStart, false);
    document
      .getElementById("three")
      .addEventListener("mousemove", fPress, false);
    document.getElementById("three").addEventListener("mouseup", fEnd, false);
    return () => {
      document
        .getElementById("three")
        .removeEventListener("mousedown", fStart, false);
      document
        .getElementById("three")
        .removeEventListener("mousemove", fPress, false);
      document
        .getElementById("three")
        .removeEventListener("mouseup", fEnd, false);
    };
  });

  return (
    <mesh visible={lasso}>
      <shapeBufferGeometry attach="geometry" args={[shape]} />
      <meshBasicMaterial
        attach="material"
        color={nightMode ? "#fff" : "#03A9F4"}
        transparent
        opacity={nightMode ? 0.05 : 0.3}
      />
    </mesh>
  );
};

export default LassoSelection;
