import { createMuiTheme } from "@material-ui/core/styles";
import blue from "@material-ui/core/colors/blue";
import pink from "@material-ui/core/colors/pink";
import red from "@material-ui/core/colors/red";
import green from "@material-ui/core/colors/green";

export const BASE_URL = "/";

export const lightTheme = createMuiTheme({
  palette: {
    type: "light",
    primary: blue,
    secondary: pink,
    error: red,
  },
});

export const darkTheme = createMuiTheme({
  palette: {
    type: "dark",
    primary: {
      main: blue[300],
    },
    secondary: {
      main: pink[500],
    },
    error: {
      main: red[300],
    },
    success: {
      main: green[400],
    },
  },
});

export const backgroundColor = "rgba(235, 235, 235, 1)";
