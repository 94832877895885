import React, { useState, FC, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

// icons
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import DescriptionOutlined from "@material-ui/icons/DescriptionOutlined";
import { IoIosArrowBack } from "react-icons/io";

import RegistrationModal from "./RegistrationModal";
import DeleteModal from "./DeleteModal";
import LogoutModal from "./LogoutModal";
import { Registration } from "../util/constant";
import { useGalaxy } from "../context/galaxy.context";
import { useTools } from "../context/tool.context";

const useStyles = makeStyles({
  list: {
    width: 300,
  },
  header: {
    position: "absolute",
    left: "1em",
    top: "50%",
    transform: "translateY(-50%)",
    fontWeight: "bold",
  },
  backButton: {
    position: "absolute",
    display: "inline-block",
    top: "50%",
    transform: "translateY(-50%)",
    right: 2,
  },
  container: {
    position: "relative",
    marginTop: 8,
    height: 50,
  },
});

interface SideBarProps {
  isSideBarOpen: boolean;
  setIsSideBarOpen: (isSideBarOpen: boolean) => void;
}

const SideBar: FC<SideBarProps> = ({ isSideBarOpen, setIsSideBarOpen }) => {
  const { dataSets, selected, getModels, loaded } = useGalaxy();
  const { setBox, setLasso, setSearch } = useTools();

  const classes = useStyles();
  const [openRegistrationModal, setRegistrationModal] = useState(false);
  const [openDeleteModal, setDeleteModal] = useState(false);
  const [openLogOut, setOpenLogOut] = useState(false);
  const registrationType = Registration.DataSet;

  useEffect(() => {
    if (loaded && selected && selected.dataSet.dataSetId === "notFound") {
      setRegistrationModal(true);
    }
  }, [loaded, selected]);

  const handleCloseDrawer = () => {
    setIsSideBarOpen(false);
  };

  const handleOpenRegModal = () => {
    setRegistrationModal(true);
  };

  const handleOpenDelModal = () => {
    setDeleteModal(true);
  };

  const handleListClick = (dataSetId: string) => {
    setLasso(false);
    setBox(false);
    setSearch(false);
    getModels(dataSetId);
  };

  const sideList = () => (
    <div className={classes.list} role="presentation">
      <div className={classes.container}>
        <h1 className={classes.header}> Doc Galaxy </h1>
        <IconButton
          className={classes.backButton}
          onClick={handleCloseDrawer}
          aria-label="close sidebar"
        >
          <IoIosArrowBack />
        </IconButton>
      </div>

      <div onClick={handleCloseDrawer} onKeyDown={handleCloseDrawer}>
        <List>
          <Divider />
          <ListItem button key={"Logout"} onClick={() => setOpenLogOut(true)}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary={"ログアウト"} />
          </ListItem>
          <Divider />
          <ListItem button key={"New Dataset"} onClick={handleOpenRegModal}>
            <ListItemIcon>
              <NoteAddIcon />
            </ListItemIcon>
            <ListItemText primary={"データセット追加"} />
          </ListItem>

          <ListItem button key={"Delete Dataset"} onClick={handleOpenDelModal}>
            <ListItemIcon>
              <DeleteForeverOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={"データセット削除"} />
          </ListItem>
        </List>
        <Divider />

        {selected ? (
          <List>
            {dataSets.map((dataSet, idx) => (
              <ListItem
                button
                selected={dataSet.dataSetId === selected.dataSet.dataSetId}
                key={idx}
                onClick={(e) => {
                  handleListClick(dataSet.dataSetId);
                }}
              >
                <ListItemIcon>
                  <DescriptionOutlined />
                </ListItemIcon>
                <ListItemText primary={dataSet.dataSetName} />
              </ListItem>
            ))}
          </List>
        ) : null}
      </div>
    </div>
  );

  return (
    <>
      <Drawer open={isSideBarOpen} onClose={handleCloseDrawer}>
        {sideList()}
      </Drawer>
      <RegistrationModal
        open={openRegistrationModal}
        setOpen={setRegistrationModal}
        registrationType={registrationType}
      />
      {selected ? (
        <DeleteModal
          open={openDeleteModal}
          setOpen={setDeleteModal}
          RegistrationType={registrationType}
        />
      ) : null}
      <LogoutModal open={openLogOut} setOpen={setOpenLogOut} />
    </>
  );
};

export default SideBar;
