import React, { FC, useState, useMemo, useEffect, useRef } from "react";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { Paper } from "@material-ui/core";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import CheckIcon from "@material-ui/icons/Check";
import axios from "axios";

import { Registration } from "../util/constant";
import { useGalaxy } from "../context/galaxy.context";
import { useUser } from "../context/user.context";
import { BASE_URL } from "../util/config";

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    boxShadow: theme.shadows[5],
    backgroundColor: theme.palette.background.default,
    borderRadius: 5,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:focus": {
      outline: "none",
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
  header: {
    display: "table",
    height: 70,
    width: "100%",
    backgroundColor: theme.palette.divider,
    padding: "8px 20px",
    position: "relative",
    borderRadius: 0,
    boxShadow: theme.shadows[0],
  },
  content: {
    padding: "20px 20px",
  },
  input: {
    display: "none",
  },
  detailContainer: {
    display: "flex",
    marginTop: "1em",
    justifyContent: "space-between",
  },
  detailSetting: {
    display: "inline-block",
    width: "50%",
    marginRight: "0.5em",
  },
  detail: {
    color: theme.palette.text.primary,
  },
  closeButton: {
    display: "table-cell",
    position: "absolute",
    right: 10,
    top: "50%",
    transform: "translateY(-50%)",
  },
  title: {
    position: "absolute",
    fontSize: "20px",
    left: "1em",
    top: "50%",
    transform: "translateY(-50%)",
  },
  buttonRoot: {
    backgroundColor: theme.palette.primary.main,
    fontWeight: "bold",
    borderRadius: "24px",
    color: "white",
  },
  successButtonRoot: {
    backgroundColor: theme.palette.success.main,
    fontWeight: "bold",
    borderRadius: "24px",
    color: "white",
    "&:hover": {
      backgroundColor: theme.palette.success.dark,
    },
  },
  submitButton: {
    backgroundColor: theme.palette.primary.main,
    fontWeight: "bold",
    color: "white",
  },
}));

interface RegistrationModal {
  open: boolean;
  setOpen: (open: boolean) => void;
  registrationType: Registration;
  dataSetName?: string;
}

const RegistrationModal: FC<RegistrationModal> = ({
  open,
  setOpen,
  registrationType,
}) => {
  const {
    selected,
    uploadDataSet,
    createModel,
    getStatus,
    setLoaded,
    getModelNaive,
    getModels,
    models,
  } = useGalaxy();

  const { logout } = useUser();
  const isDataSet = registrationType === Registration.DataSet;

  const initialModel = useMemo(() => {
    return {
      dataSetId: selected ? selected.dataSet.dataSetId : "",
      modelName: "Trial Model",
      modelParams: {
        flow: "doc2vec-umap-hdbscan",
        doc2vec: {
          seed: 0,
          vectorDim: 400,
          epoch: 100,
        },
        umap: {
          randomState: 0,
        },
        hdbscan: {
          minClusterSize: 50,
        },
      },
    };
  }, [selected]);

  const initialDataSet = useMemo(() => {
    return {
      dataSetName: " ",
      csvFile: {
        name: "",
        lastModified: 0,
        lastModifiedDate: "",
        webkitRelativePath: "",
        size: 0,
        type: "text/csv",
      },
    };
  }, []);

  const initialError = {
    dataSetName: false,
    epoch: false,
    file: false,
    modelName: false,
    seed: false,
    randomState: false,
    minClusterSize: false,
    vectorDim: false,
  };

  const classes = useStyles();
  const [dataSetParam, setDataSetParam] = useState(initialDataSet);
  const model = useRef(initialModel);
  const modelParams = useRef(initialModel.modelParams);
  const doc2vec = useRef(initialModel.modelParams.doc2vec);
  const umap = useRef(initialModel.modelParams.umap);
  const hdbscan = useRef(initialModel.modelParams.hdbscan);
  const [error, setError] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [modelCreating, setModelCreating] = useState(false);
  const { token } = useUser();
  const [creatingModel, setCreatingModel] = useState("");

  const FIELDS = {
    dataSetName: "dataSetName",
    modelName: "modelName",
    file: "csvFile",
    seed: "seed",
    epoch: "epoch",
    vectorDim: "vectorDim",
    randomState: "randomState",
    minClusterSize: "minClusterSize",
  };

  const handleCloseModal = () => {
    setDataSetParam({ ...initialDataSet });
    setOpen(false);
  };

  const errorMessage = (error: boolean) => {
    return error ? "入力してください" : "";
  };

  const validation = () => {
    let isPerfect = true;
    if (isDataSet) {
      isPerfect =
        Boolean(dataSetParam.dataSetName) && Boolean(dataSetParam.csvFile.name);
    } else {
      isPerfect =
        Boolean(model.current.modelName) &&
        model.current.modelName !== initialModel.modelName;
      Boolean(modelParams.current.doc2vec.epoch) &&
        // Boolean(modelParams.current.doc2vec.seed) &&
        Boolean(modelParams.current.doc2vec.vectorDim) &&
        // Boolean(modelParams.current.umap.randomState) &&
        Boolean(modelParams.current.hdbscan.minClusterSize);
    }

    return isPerfect;
  };

  const onChangeData = (field: string) => (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const input = e.target as HTMLInputElement;
    setDataSetParam({
      ...dataSetParam,
      [field]: input.value,
    });
  };

  const uploadFile = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const input = e.target as HTMLInputElement;
    if (input.files === null) return;
    const file = input.files.item(0);
    if (file === null) return;
    setDataSetParam({
      ...dataSetParam,
      dataSetName: file.name.slice(0, -4),
      [FIELDS.file]: file,
    });
  };

  const onChangeModel = (field: string) => (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const input = e.target as HTMLInputElement;
    let updateValue: any = "";
    let updateKey = "";
    if (["epoch", "seed", "vectorDim"].indexOf(field) !== -1) {
      updateKey = "doc2vec";
      if (field === "epoch") doc2vec.current.epoch = Number(input.value);
      if (field === "seed") doc2vec.current.seed = Number(input.value);
      if (field === "vectorDim")
        doc2vec.current.vectorDim = Number(input.value);

      updateValue = doc2vec.current;
    } else if (["randomState"].indexOf(field) !== -1) {
      updateKey = "umap";
      if (field === "randomState")
        umap.current.randomState = Number(input.value);

      updateValue = umap.current;
    } else {
      updateKey = "hdbscan";
      if (field === "minClusterSize")
        hdbscan.current.minClusterSize = Number(input.value);
      updateValue = hdbscan.current;
    }

    if (field === "modelName") model.current.modelName = input.value;
    modelParams.current = {
      ...modelParams.current,
      [updateKey]: updateValue,
    };

    model.current = { ...model.current, modelParams: modelParams.current };
  };

  const onSubmit = () => {
    setError(!validation());
    if (!validation()) {
      console.log("入力エラー");
      return;
    }

    setSubmitting(true);
    if (isDataSet) {
      setLoaded(true);
      uploadDataSet(dataSetParam).then((dataSetId: string) => {
        if (!dataSetId) {
          setError(true);
          setSubmitting(false);
          return;
        }
        createModel({ ...model.current, dataSetId: dataSetId });

        setSubmitting(false);
        setOpen(false);
        setModelCreating(true);
      });
    } else {
      // setModel({ ...model, modelParams: modelParams.current });
      createModel({
        ...model.current,
        dataSetId: selected.dataSet.dataSetId,
      }).then((res) => {
        console.log("res", res);
        if (res === "error") {
          setError(true);
          setSubmitting(false);
          return;
        }
        setSubmitting(false);
        setOpen(false);
        setModelCreating(true);
      });
    }
  };

  useEffect(() => {
    let intervalId: any;
    if (modelCreating) {
      intervalId = setInterval(async () => {
        console.log("start");
        const res = await axios
          .get(BASE_URL + "api/models", {
            params: { dataset_id: selected.dataSet.dataSetId },
            headers: {
              "Content-Type": "application/json",
              Authorization: `bearer ${token}`,
            },
          })
          .catch((e) => {
            console.log("APIError", e);
            if (e.response.status === 401) {
              logout();
            }
            return e.response;
          });
        if (res.status !== 200) return;

        console.log("end");
        const pendingModel = res.data.filter((model: any) => {
          return model.state.indexOf("progress") !== -1;
        });

        if (pendingModel.length > 0) {
          setCreatingModel(res.data.model_id);
        }

        getStatus();
      }, 5000);
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [
    creatingModel,
    getModelNaive,
    getModels,
    getStatus,
    logout,
    modelCreating,
    models,
    selected.dataSet.dataSetId,
    token,
  ]);

  return (
    <>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={classes.modal}
        open={open}
        closeAfterTransition={true}
        onClose={() => {
          handleCloseModal();
        }}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Paper className={classes.paper}>
            <Paper className={classes.header} elevation={10} square={true}>
              <p className={classes.title}>
                {isDataSet ? "データセットを追加する" : "モデルを追加する"}
              </p>
              <IconButton
                className={classes.closeButton}
                onClick={() => {
                  handleCloseModal();
                }}
              >
                <ClearIcon fontSize={"large"} />
              </IconButton>
            </Paper>
            <Paper className={classes.content} elevation={1} square={false}>
              {/* 必須項目の入力 */}
              {error && (
                <div
                  style={{
                    color: "#f00",
                    fontWeight: "bold",
                    marginBottom: 8,
                    textAlign: "center",
                  }}
                >
                  入力に誤りがあります。
                </div>
              )}
              {isDataSet && (
                <div
                  style={{
                    marginTop: "10px",
                    textAlign: "center",
                  }}
                >
                  <div
                    style={{
                      margin: "0 auto",
                      width: "200px",
                      display: "inline-block",
                    }}
                  >
                    <input
                      accept=".csv"
                      className={classes.input}
                      id="outlined-button-file"
                      multiple
                      type="file"
                      onChange={(e) => uploadFile(e)}
                    />
                    <label htmlFor="outlined-button-file">
                      <Button
                        classes={
                          dataSetParam.csvFile.name
                            ? { root: classes.successButtonRoot }
                            : { root: classes.buttonRoot }
                        }
                        color={"primary"}
                        component="span"
                        variant="contained"
                        disabled={submitting}
                        startIcon={
                          dataSetParam.csvFile.name ? (
                            <CheckIcon />
                          ) : (
                            <NoteAddIcon />
                          )
                        }
                        style={{
                          marginBottom: "15px",
                        }}
                      >
                        {dataSetParam.csvFile.name ? (
                          <span>アップロード済み</span>
                        ) : (
                          <span>CSVをアップロード</span>
                        )}
                      </Button>
                    </label>
                  </div>
                </div>
              )}
              <FormControl fullWidth={true}>
                {isDataSet && (
                  <TextField
                    id="dataset-name"
                    label="データセット名"
                    autoFocus
                    value={dataSetParam.dataSetName}
                    error={!Boolean(dataSetParam.dataSetName)}
                    helperText={
                      !Boolean(dataSetParam.dataSetName)
                        ? "入力してください"
                        : ""
                    }
                    onChange={onChangeData(FIELDS.dataSetName)}
                  />
                )}
                {!isDataSet && (
                  <>
                    <TextField
                      id="model-name"
                      label="モデル名"
                      autoFocus={!isDataSet}
                      onChange={onChangeModel(FIELDS.modelName)}
                      error={!Boolean(model.current.modelName)}
                      helperText={
                        !Boolean(model.current.modelName)
                          ? "入力してください"
                          : ""
                      }
                    />
                  </>
                )}

                {/* 追加項目の入力 */}

                {!isDataSet && (
                  <>
                    <span style={{ marginTop: "1.5em" }}>モデルの設定</span>
                    <div className={classes.detailContainer}>
                      <TextField
                        className={classes.detailSetting}
                        id="epochs"
                        label="学習回数"
                        type="number"
                        defaultValue={model.current.modelParams.doc2vec.epoch}
                        error={!Boolean(modelParams.current.doc2vec.epoch)}
                        helperText={
                          !Boolean(modelParams.current.doc2vec.epoch)
                            ? "1以上で入力してください"
                            : ""
                        }
                        onChange={onChangeModel(FIELDS.epoch)}
                      />
                      {/* <TextField
                        className={classes.detailSetting}
                        id="vector dim"
                        label="ベクトルの次元"
                        type="number"
                        defaultValue={
                          model.current.modelParams.doc2vec.vectorDim
                        }
                        error={!Boolean(modelParams.current.doc2vec.vectorDim)}
                        helperText={
                          !Boolean(modelParams.current.doc2vec.vectorDim)
                            ? "1以上で入力してください"
                            : ""
                        }
                        onChange={onChangeModel(FIELDS.vectorDim)}
                      /> */}
                      <TextField
                        className={classes.detailSetting}
                        id="min_cluster_size"
                        label="最小クラスタサイズ"
                        type="number"
                        defaultValue={
                          model.current.modelParams.hdbscan.minClusterSize
                        }
                        error={
                          !Boolean(modelParams.current.hdbscan.minClusterSize)
                        }
                        helperText={
                          !Boolean(modelParams.current.hdbscan.minClusterSize)
                            ? "1以上で入力してください"
                            : ""
                        }
                        onChange={onChangeModel(FIELDS.minClusterSize)}
                      />
                      {/* <TextField
                        className={classes.detailSetting}
                        id="seed"
                        label="seed"
                        type="number"
                        defaultValue={model.current.modelParams.doc2vec.seed}
                        helperText={errorMessage(error.epoch)}
                        onChange={onChangeModel(FIELDS.seed)}
                      /> */}
                    </div>
                    {/* <div className={classes.detailContainer}>
                      <TextField
                        className={classes.detailSetting}
                        id="random_state"
                        label="random_state"
                        type="number"
                        defaultValue={
                          model.current.modelParams.umap.randomState
                        }
                        helperText={errorMessage(error.epoch)}
                        onChange={onChangeModel(FIELDS.randomState)}
                      />
                    </div> */}
                  </>
                )}
              </FormControl>

              {/* 実行ボタン */}
              <div style={{ marginTop: "15px", textAlign: "right" }}>
                <Button
                  style={{ marginRight: "1em" }}
                  onClick={handleCloseModal}
                >
                  キャンセル
                </Button>
                <Button
                  classes={{ root: classes.submitButton }}
                  variant="contained"
                  color="primary"
                  onClick={onSubmit}
                  disabled={submitting}
                >
                  学習開始
                </Button>
              </div>
            </Paper>
          </Paper>
        </Fade>
      </Modal>
    </>
  );
};

export default RegistrationModal;
