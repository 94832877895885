/** @jsx jsx */
import { FC, useEffect } from "react";
// eslint-disable-next-line
import { Canvas, Dom } from "react-three-fiber";
import { css, jsx, Global } from "@emotion/core";
import emotionReset from "emotion-reset";
import { Color } from "three";

import LOD from "../three_util/LOD";
import Effect from "../three_util/Effect";
import Controls from "../three_util/Controls";
import LassoSelection from "../three_util/LassoSelection";
import BoxSelection from "../three_util/BoxSelection";
import BoxEdge from "../three_util/BoxEdge";
import LassoEdge from "../three_util/LassoEdge";
import { backgroundColor } from "../util/config";

import { useGalaxy } from "../context/galaxy.context";
import { useTools } from "../context/tool.context";

const globalStyles = css`
  ${emotionReset}
  *, *::after, *::before {
    box-sizing: border-box;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
  }
  .selectBox {
    border: 2px solid #55aaff;
    background-color: rgba(75, 160, 255, 0.3);
    position: fixed;
  }
`;

const theme = css`
  position: absolute;
  z-index: 1;
  width: 100vw;
  height: 100vh;
`;

const dayBackGround = css(theme, {
  backgroundColor: backgroundColor,
  // backgroundColor: "rgb(233,245,233)" // karakuri Mode
});

const nightBackGround = css(theme, {
  backgroundColor: "rgb(0, 0, 0, 1)",
});

interface ThreeProps {
  nightMode: boolean;
}

const Three: FC<ThreeProps> = ({ nightMode }) => {
  const { lasso, box, labelMode, zoom, setZoom, setSearch } = useTools();
  const { selected, loaded, setSelectedPlots, setSelected } = useGalaxy();
  useEffect(() => {
    selected.labels.forEach((label: labels) => {
      if (label.color !== "#fff") {
        label.color = "";
      }
    });
    return () => {
      setZoom((prev: number) => prev + 0.001);
    };
  }, [nightMode, selected.labels, setZoom]);

  return (
    <div id="three">
      <Global styles={globalStyles} />
      <div css={nightMode ? nightBackGround : dayBackGround}>
        <Canvas
          className="canvas"
          id="canvas"
          camera={{
            top: window.innerHeight / 2,
            bottom: -window.innerHeight / 2,
            right: window.innerWidth / 2,
            left: -window.innerWidth / 2,
            near: -100,
            far: 1500,
            position: [0, 0, 15],
          }}
          gl={{ preserveDrawingBuffer: true }}
          orthographic={true}
          shadowMap
          onCreated={({ gl }) => {
            gl.setClearColor(new Color("#ffffff"), 0.01);
          }}
        >
          <ambientLight intensity={1} />
          {loaded && selected && selected.plot.length !== 1 && (
            <LOD
              nightMode={nightMode}
              labelMode={labelMode}
              selected={selected}
              dataSet={selected.plot}
              labels={selected.labels}
              setSelectedPlots={setSelectedPlots}
              setSelected={setSelected}
              setSearch={setSearch}
            />
          )}

          <Effect isRealMode={nightMode} />
          {loaded && (
            <Controls
              isControl={true}
              lasso={lasso}
              box={box}
              zoom={zoom}
              setZoom={setZoom}
              loaded={loaded}
            />
          )}
          {loaded && box && (
            <BoxSelection
              nightMode={nightMode}
              box={box}
              dataSet={selected.plot}
              setSelectedPlots={setSelectedPlots}
            />
          )}
          {loaded && lasso && (
            <LassoSelection
              lasso={lasso}
              nightMode={nightMode}
              dataSet={selected.plot}
              setSelectedPlots={setSelectedPlots}
            />
          )}
          {box && <BoxEdge />}
          {lasso && <LassoEdge />}
        </Canvas>
      </div>
    </div>
  );
};

export default Three;
