import React, { useEffect } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import Slide from "@material-ui/core/Slide";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import { useGalaxy } from "../context/galaxy.context";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
    container: {
      position: "absolute",
      zIndex: 50,
      left: "50%",
      bottom: 32,
      transform: "translate(-50%, 0)",
    },
    iconButton: {
      position: "absolute",
      right: 0,
      padding: 20,
    },
  })
);

export default function SimpleAlerts() {
  const classes = useStyles();
  const {
    openState,
    setOpenState,
    setPlotAndLabels,
    selected,
    setLoaded,
  } = useGalaxy();

  return (
    <>
      <div className={classes.container}>
        <Slide direction="up" in={openState} mountOnEnter unmountOnExit>
          <div className={classes.root}>
            <Alert
              icon={false}
              severity="success"
              action={
                <IconButton
                  size="small"
                  onClick={() => {
                    setOpenState(false);
                  }}
                >
                  <ClearIcon fontSize="inherit" />
                </IconButton>
              }
            >
              学習が完了しました！画面を更新してください！
            </Alert>
          </div>
        </Slide>
      </div>
    </>
  );
}
