import React, { FC } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import AddRounded from "@material-ui/icons/AddRounded";
import RemoveRounded from "@material-ui/icons/RemoveRounded";
import Tooltip from "@material-ui/core/Tooltip";

import { useTools } from "../context/tool.context";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: "absolute",
    zIndex: 100,
    right: 20,
    bottom: 92,
    backgroundColor: theme.palette.background.paper,
    borderRadius: "10px",
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    width: 28,
    margin: 4,
  },
}));

const Scales: FC = () => {
  const classes = useStyles();
  const { setZoom } = useTools();

  const handleZoomIn = () => {
    setZoom((prev: number) => prev * 1.5);
  };

  const handleZoomOut = () => {
    setZoom((prev: number) => {
      if (prev <= 1) return prev;
      else return prev / 1.5;
    });
  };
  return (
    <Paper className={classes.root}>
      <Tooltip title="拡大" placement="left">
        <IconButton
          className={classes.iconButton}
          aria-label="expansion"
          onClick={handleZoomIn}
        >
          <AddRounded />
        </IconButton>
      </Tooltip>

      <Divider />
      <Tooltip title="縮小" placement="left">
        <IconButton
          className={classes.iconButton}
          aria-label="reduction"
          onClick={handleZoomOut}
        >
          <RemoveRounded />
        </IconButton>
      </Tooltip>
    </Paper>
  );
};

export default Scales;
