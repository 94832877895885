import React, {
  FC,
  useMemo,
  useEffect,
  useState,
  useRef,
  useCallback,
} from "react";
import { hover } from "glamor";
import { useThree, Dom, useFrame } from "react-three-fiber";
import { Vector3 } from "three";
import Tooltip from "@material-ui/core/Tooltip";

import Plot from "../object/Plot";
import { Lod, zf } from "../util/constant";
import { clippingToWorld } from "../three_util/LassoSelection";

import "../container/Three.css";
// import { useGalaxy } from "../context/galaxy.context";

interface LODProps {
  nightMode: boolean;
  labelMode: boolean;
  dataSet: plot[];
  labels: labels[];
  setSelectedPlots: any;
  selected: selected;
  setSelected: any;
  setSearch: any;
}

// クラスタ表示に関するコンポーネント(プロット、テキスト、アノテーション)
const LOD: FC<LODProps> = ({
  nightMode,
  labelMode,
  dataSet,
  labels,
  selected,
  setSelectedPlots,
  setSelected,
  setSearch,
}) => {
  const { camera, gl } = useThree();
  selected.plot.forEach((element) => {
    if (element.searched) {
      // alert("サーチドテキスト");
    }
  });

  labels.sort(function (a, b) {
    if (a.size > b.size) return -1;
    if (a.size < b.size) return 1;
    return 0;
  });

  const handleDoubleClick = (cluster_id: number) => {
    const cluster = dataSet.filter((plot) => {
      return plot.cluster_id.indexOf(cluster_id) !== -1;
    });
    if (cluster) {
      setSelectedPlots(cluster);
      setSearch(true);
    }
  };

  // eslint-disable-next-line
  const [press, setPress] = useState(false);

  const view = useRef({
    high: new Vector3(0, 0, 0),
    low: new Vector3(0, 0, 0),
  });

  const [lod, setLod] = useState(Lod.Overall);

  const handleSetPressT = () => {
    setPress(true);
  };

  const handleSetPressF = () => {
    setPress(false);
  };
  // ZoomでLodを切替る
  useFrame(() => {
    // console.log("zoom", camera.zoom);
    view.current.high = clippingToWorld(1, 1, camera);
    view.current.low = clippingToWorld(-1, -1, camera);
    if (camera.zoom <= 3) {
      // プロットモード
      // console.log("overall");
      setLod(Lod.Overall);
    } else if (camera.zoom > 3) {
      // テキストモード
      if (camera.zoom > 3 && camera.zoom <= 10) {
        // console.log("middle");
        setLod(Lod.Middle);
      } else if (camera.zoom > 10 && camera.zoom <= 40) {
        // console.log("detail 1");
        setLod(Lod.Detail1);
      } else if (camera.zoom > 40 && camera.zoom <= 100) {
        // console.log("detail 2");
        setLod(Lod.Detail2);
      } else {
        // console.log("detail 3");
        setLod(Lod.Detail3);
      }
      gl.domElement.addEventListener("mousedown", handleSetPressT, false);
      gl.domElement.addEventListener("mouseup", handleSetPressF, false);
    }
    return () => {
      gl.domElement.removeEventListener("mousedown", handleSetPressT, false);
      gl.domElement.removeEventListener("mouseup", handleSetPressF, false);
    };
  });

  const factor = 0;

  const createBoundingClientRect = useCallback(
    (e: any) => {
      var x =
        window.pageXOffset !== undefined
          ? window.pageXOffset
          : (
              document.documentElement ||
              document.body.parentNode ||
              document.body
            ).scrollLeft;
      var y =
        window.pageYOffset !== undefined
          ? window.pageYOffset
          : (
              document.documentElement ||
              document.body.parentNode ||
              document.body
            ).scrollTop;
      var rect = e.getBoundingClientRect();
      var width = rect.width;
      var height = rect.height;
      var xStart = rect.left + x;
      var xEnd = xStart + width;
      var yStart = rect.top + y;
      var yEnd = yStart + height;
      return {
        rect: rect,
        width: width,
        height: height,
        xStart: xStart - factor,
        xEnd: xEnd + factor,
        yStart: yStart - factor,
        yEnd: yEnd + factor,
      };
    },
    [factor]
  );

  const detectCollision = (rect1: any, rect2: any) => {
    if (
      ((rect1.xStart <= rect2.xStart && rect2.xStart <= rect1.xEnd) ||
        (rect1.xStart <= rect2.xEnd && rect2.xEnd <= rect1.xEnd)) &&
      ((rect1.yStart <= rect2.yStart && rect2.yStart <= rect1.yEnd) ||
        (rect1.yStart <= rect2.yEnd && rect2.yEnd <= rect1.yEnd))
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    return () => {
      gl.dispose();
    };
  }, [gl]);

  useEffect(() => {
    const texts = document.querySelectorAll("#text");
    const clusters = document.querySelectorAll(".clusterStyle");
    // console.log("clusters", clusters);
    for (let i = 0; i < texts.length; i++) {
      for (let j = i + 1; j < texts.length; j++) {
        const left = createBoundingClientRect(texts[i]);
        const right = createBoundingClientRect(texts[j]);
        // console.log(left, right);
        if (detectCollision(left, right)) {
          // console.warn("衝突/text");
          // console.log(texts[i], texts[j]);
          (texts[i] as HTMLInputElement).style.display = "none";
        }
      }
      for (let j = i + 1; j < clusters.length; j++) {
        const left = createBoundingClientRect(texts[i]);
        const right = createBoundingClientRect(clusters[j]);
        // console.log(left, right);
        if (detectCollision(left, right)) {
          // console.warn("衝突.cluster");
          // console.log(texts[i], texts[j]);
          (texts[i] as HTMLInputElement).style.display = "none";
        }
      }
    }
  }, [camera.zoom, createBoundingClientRect, view.current.high]);

  const nowLabels = useMemo(() => {
    return selected.labels
      .filter((label) => {
        return (
          label.range[0] <= camera.zoom * zf + 3 &&
          (label.range[1] >= camera.zoom * zf + 3 || label.range[1] === -1)
        );
      })
      .map((label) => label.cluster_id);
  }, [camera.zoom, selected.labels]);

  const isNowLabel = useCallback(
    (cluster_id: number) => {
      return nowLabels.indexOf(cluster_id) !== -1 ? true : false;
    },
    [nowLabels]
  );

  const getNoeLabel = (plot: plot) => {
    const nowLabel = plot.cluster_id.filter((cluster_id) => {
      return isNowLabel(cluster_id);
    });
    let label;
    if (nowLabel.length === 0) {
      label = labels.find((label) => {
        return label.cluster_id === plot.cluster_id[0];
      });
    } else {
      label = labels.find((label) => {
        return label.cluster_id === nowLabel[0];
      });
    }
    return label;
  };

  return (
    <>
      {/* プロット */}
      <group>
        (
        <Plot
          dataSet={dataSet}
          labels={labels}
          nightMode={nightMode}
          lod={lod}
        />
      </group>
      {/* テキスト */}
      {labelMode &&
        (lod === Lod.Middle ||
          lod === Lod.Detail1 ||
          lod === Lod.Detail2 ||
          lod === Lod.Detail3) &&
        dataSet
          .filter((plot) => {
            return (
              plot.coordinate[0] * 100 < view.current.high.x &&
              plot.coordinate[0] * 100 > view.current.low.x &&
              plot.coordinate[1] * 100 < view.current.high.y &&
              plot.coordinate[1] * 100 > view.current.low.y
            );
          })
          .slice(
            0,
            lod === Lod.Middle
              ? 8
              : lod === Lod.Detail1
              ? 13
              : lod === Lod.Detail2
              ? 15
              : 30
          )
          .map((plot, idx) => (
            <Dom
              key={idx}
              center
              position={[plot.coordinate[0] * 100, plot.coordinate[1] * 100, 0]}
            >
              <div id="text">
                <Tooltip
                  arrow
                  title={
                    <>
                      labels:{getNoeLabel(plot)?.words[0]}{" "}
                      {getNoeLabel(plot)?.words[1]}{" "}
                      {getNoeLabel(plot)?.words[2]}
                      <hr />
                      {plot.text}
                    </>
                  }
                  enterDelay={0}
                  leaveDelay={0}
                >
                  <div
                    {...hover({
                      color: "#2196f3",
                    })}
                  >
                    <span></span>
                    {lod === Lod.Middle && (
                      <p className={"textStyle textMiddle"}>
                        {plot.text.length >= 19
                          ? plot.text.slice(0, 19) + "…"
                          : plot.text}
                      </p>
                    )}
                    {lod === Lod.Detail1 && (
                      <p className={"textStyle textDetail1"}>
                        {plot.text.length >= 19
                          ? plot.text.slice(0, 19) + "…"
                          : plot.text}
                      </p>
                    )}
                    {lod === Lod.Detail2 && (
                      <p className={"textStyle textDetail2"}>
                        {plot.text.length >= 29
                          ? plot.text.slice(0, 29) + "…"
                          : plot.text}
                      </p>
                    )}
                    {lod === Lod.Detail3 && (
                      <p className={"textStyle textDetail3"}>{plot.text}</p>
                    )}
                  </div>
                </Tooltip>
              </div>
            </Dom>
          ))}
      {/* {lod === Lod.Detail ? detail : null} */}

      {/* ラベル */}
      {labelMode &&
        labels
          .filter((label) => {
            return (
              label.range[0] <= camera.zoom * zf + 3 &&
              (label.range[1] >= camera.zoom * zf + 3 || label.range[1] === -1)
            );
          })
          .map((label, idx) => (
            <Dom
              className="test"
              center={false}
              prepend={true}
              position={[
                label.coordinate[0] * 100,
                label.coordinate[1] * 100,
                20,
              ]}
              key={idx}
            >
              <div
                className={"containerStyle"}
                onDoubleClick={(e) => {
                  console.log(
                    label.cluster_id,
                    label.size,
                    label.range,
                    label.words
                  );
                  handleDoubleClick(label.cluster_id);
                }}
              >
                <span
                  style={{
                    backgroundColor: label.hue
                      ? `hsl(${Math.abs(label.hue) % 360} 50% 30%)`
                      : "",
                  }}
                  className={"sizeStyle"}
                >
                  {label.size}
                </span>
                <div className={"clusterStyle"}>
                  <p className={"topWord"}>
                    {label.words[0] === " " ? "Top Word" : label.words[0]}
                  </p>
                  <p className={"otherWords"}>
                    {label.words[1]} {label.words[2]}
                  </p>
                </div>
              </div>
            </Dom>
          ))}
    </>
  );
};

export default LOD;
