import React from "react";
import { Redirect } from "react-router-dom";
import { useUser } from "../context/user.context";

const Auth = props => {
  const { isLoggedIn } = useUser();
  return isLoggedIn === "1" ? props.children : <Redirect to="/login" />;
};

export default Auth;
