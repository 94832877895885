import React, { FC, useEffect, useState } from "react";

import { ThemeProvider } from "@material-ui/core/styles";

import SearchBox from "../components/SearchBox";
import ToolBar from "../components/ToolBar";
import Scales from "../components/Scales";
import SideBar from "../components/SideBar";
import ModelBox from "../components/ModelBox";
import SelectedPlotsTable from "../components/selectedPlotsTable";
import Footer from "../components/Footer";
import Alert from "../components/Alert";
import Loading from "../components/Loading";
import { useTools } from "../context/tool.context";
import { useGalaxy } from "../context/galaxy.context";
import { lightTheme, darkTheme } from "../util/config";

import "../App.css";

interface ToolsPros {
  nightMode: boolean;
}

const Tools: FC<ToolsPros> = ({ nightMode }) => {
  const [isSideBarOpen, setIsSideBarOpen] = useState(false);
  const { lasso, box, labelMode, search } = useTools();
  const theme = nightMode ? darkTheme : lightTheme;
  const { loaded } = useGalaxy();

  return (
    <>
      <ThemeProvider theme={theme}>
        <SearchBox setIsSideBarOpen={setIsSideBarOpen} />
        <ModelBox isNightMode={nightMode} />
        <SideBar
          isSideBarOpen={isSideBarOpen}
          setIsSideBarOpen={setIsSideBarOpen}
        />
        <ToolBar
          lasso={lasso}
          box={box}
          labelMode={labelMode}
          nightMode={nightMode}
        />
        <Scales />
        <SelectedPlotsTable box={box} lasso={lasso} search={search} />
        <Alert />
        <Footer />
        {!loaded && <Loading />}
      </ThemeProvider>
    </>
  );
};

export default Tools;
