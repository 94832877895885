import React, { useRef, useEffect, memo } from "react";
import { useThree, extend, useFrame } from "react-three-fiber";
import * as meshline from "threejs-meshline";
import { Vector3 } from "three";

extend(meshline);

// クリッピング座標をワールド座標に変換
const clippingToWorld = (x, y, camera) => {
  let vector = new Vector3(x, y, 1);
  vector.unproject(camera);
  return vector;
};

const BoxSelection = memo(() => {
  const { mouse, camera, gl } = useThree();

  const vertices = useRef([]);
  const verticesRef = useRef();
  const start = useRef();
  const isSelectMode = useRef(false);
  const materialRef = useRef();
  const canvas = gl.domElement;
  const lineWidth = 2;

  const z = 5;

  const initContour = (vertices, vector, z) => {
    vertices.current.length = 0;
    vertices.current.push(new Vector3(start.current.x, start.current.y, z));
    vertices.current.push(new Vector3(vector.x, start.current.y, z));
    vertices.current.push(new Vector3(vector.x, vector.y, z));
    vertices.current.push(new Vector3(start.current.x, vector.y, z));
    vertices.current.push(new Vector3(start.current.x, start.current.y, z));
  };

  const fStart = event => {
    if (event.altKey) return;
    if (!isSelectMode.current) {
      isSelectMode.current = true;
      vertices.current.length = 0;
      start.current = clippingToWorld(mouse.x, mouse.y, camera);
      vertices.current.push(new Vector3(start.current.x, start.current.y, z));
      verticesRef.current.setVertices(vertices.current);
    }
  };
  const fMiddle = event => {
    if (event.altKey) return;
    if (isSelectMode.current) {
      canvas.style.cursor = "default";
      let vector = clippingToWorld(mouse.x, mouse.y, camera);
      initContour(vertices, vector, z);
      verticesRef.current.setVertices(vertices.current);
    }
  };

  const fEnd = () => {
    if (isSelectMode.current) {
      isSelectMode.current = false;
      let vector = clippingToWorld(mouse.x, mouse.y, camera);
      // vertices.current.push(new Vector3(start.current.x, start.current.y, z));
      initContour(vertices, vector, z);
      verticesRef.current.setVertices(vertices.current);
    }
  };

  useEffect(() => {
    document
      .getElementById("three")
      .addEventListener("mousedown", fStart, false);
    document
      .getElementById("three")
      .addEventListener("mousemove", fMiddle, false);
    document.getElementById("three").addEventListener("mouseup", fEnd, false);
    return () => {
      document
        .getElementById("three")
        .removeEventListener("mousedown", fStart, false);
      document
        .getElementById("three")
        .removeEventListener("mousemove", fMiddle, false);
      document
        .getElementById("three")
        .removeEventListener("mouseup", fEnd, false);
    };
  });

  useFrame(() => {
    materialRef.current.lineWidth = lineWidth / camera.zoom;
  });

  return (
    <>
      <mesh>
        <meshLine attach="geometry" vertices={[]} ref={verticesRef} />
        <meshLineMaterial
          attach="material"
          color={"#2196F3"}
          lineWidth={2}
          ref={materialRef}
        />
      </mesh>
    </>
  );
});

export default BoxSelection;
