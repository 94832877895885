import React, {
  useState,
  useContext,
  createContext,
  useCallback,
  FC
} from "react";
import { useGalaxy } from "./galaxy.context";

export const ToolsContext = createContext({
  nightMode: false,
  lasso: false,
  box: false,
  labelMode: true,
  zoom: 1,
  search: false,
  setNightMode: (nightMode: boolean): void => {},
  setLasso: (lasso: boolean) => {},
  setBox: (box: boolean): void => {},
  setLabelMode: (labelMode: boolean): void => {},
  setZoom: (prev: any): void => {},
  setSearch: (search: boolean): void => {}
});

export const ToolsProvider: FC = ({ children }) => {
  const [nightMode, setOriginalNightMode] = useState(false);
  const [lasso, setOriginalLasso] = useState(false);
  const [box, setOriginalBox] = useState(false);
  const [labelMode, setOriginalLabelMode] = useState(true);
  const [zoom, setOriginalZoom] = useState<number>(1);
  const [search, setOriginalSearch] = useState(false);

  const setNightMode = useCallback(nightMode => {
    setOriginalNightMode(nightMode);
  }, []);
  const setLasso = useCallback(lasso => {
    setOriginalLasso(lasso);
  }, []);
  const setBox = useCallback(box => {
    setOriginalBox(box);
  }, []);
  const setZoom = useCallback(zoom => {
    setOriginalZoom(zoom);
  }, []);
  const setLabelMode = useCallback(label => {
    setOriginalLabelMode(label);
  }, []);
  const setSearch = useCallback(search => {
    setOriginalSearch(search);
  }, []);

  const value = {
    nightMode,
    lasso,
    box,
    labelMode,
    zoom,
    search,
    setNightMode,
    setLasso,
    setBox,
    setLabelMode,
    setZoom,
    setSearch
  };

  return <ToolsContext.Provider value={value} children={children} />;
};
export function useTools() {
  return useContext(ToolsContext);
}
