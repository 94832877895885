import React, { FC } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { useGalaxy } from "../context/galaxy.context";
import { useTools } from "../context/tool.context";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: "absolute",
    zIndex: 1000,
    display: "flex",
    justifyContent: "space-between",
    bottom: 0,
    background:
      theme.palette.type === "light"
        ? "rgba(255,255,255,0.5)"
        : "rgba(200,200,200,0.1)",
    width: "100%",
    padding: "5px 2em",
    fontSize: "0.7em",
    color: theme.palette.text.primary,
    fontWeight: 600,
  },
}));

const Footer: FC = () => {
  const classes = useStyles();
  const { learningModel, selected } = useGalaxy();
  const { zoom } = useTools();

  return (
    <div className={classes.container}>
      <span>
        データ点数:{" "}
        {selected && selected.plot.length === 1 ? "-" : selected.plot.length}
        &nbsp;&nbsp;&nbsp; 学習中:
        {learningModel.length > 0
          ? learningModel.map((model: state) => ` ${model.model}`)
          : " 学習中モデルなし"}
      </span>

      <span>拡大率: {zoom.toFixed(2)}</span>
    </div>
  );
};

export default Footer;
