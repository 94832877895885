import React, { FC } from "react";

import { withRouter } from "react-router-dom";
import Three from "./Three";
import Tools from "../container/Tools";
import { useTools } from "../context/tool.context";

import "../App.css";

const Main: FC = () => {
  const { nightMode } = useTools();
  return (
    <div className={"container"}>
      <Tools nightMode={nightMode} />
      <Three nightMode={nightMode} />
    </div>
  );
};

export default withRouter(Main);
