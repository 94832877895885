import React, { FC } from "react";
import Button from "@material-ui/core/Button";
import { Paper } from "@material-ui/core";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";

import { makeStyles, Theme, withStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";

import { useUser } from "../context/user.context";

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    position: "absolute",
    width: 350,
    boxShadow: theme.shadows[5],
    backgroundColor: theme.palette.background.default,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:focus": {
      outline: "none",
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
  header: {
    display: "table",
    height: 50,
    width: "100%",
    backgroundColor: theme.palette.divider,
    padding: "8px 20px",
    position: "relative",
    borderRadius: 0,
    boxShadow: theme.shadows[0],
  },
  closeButton: {
    display: "table-cell",
    position: "absolute",
    right: 10,
    top: "50%",
    transform: "translateY(-50%)",
  },
  title: {
    position: "absolute",
    fontSize: "20px",
    left: "1em",
    top: "50%",
    transform: "translateY(-50%)",
  },
  message: {
    color: theme.palette.text.primary,
    textAlign: "center",
    marginTop: "10px",
    marginBottom: "15px",
    fontSize: "1.2em",
  },
  content: {
    padding: "20px 20px",
  },
}));

const CancelButton = withStyles((theme: Theme) => ({
  root: {
    color: "white",
  },
  contained: {
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.secondary.main
        : theme.palette.secondary.light,
  },
}))(Button);

interface Modal {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const DeleteModal: FC<Modal> = ({ open, setOpen }) => {
  const classes = useStyles();
  const { logout } = useUser();

  const handleDelete = () => {
    logout();
    setOpen(false);
  };

  return (
    <>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={classes.modal}
        open={open}
        closeAfterTransition={true}
        onClose={() => {
          setOpen(false);
        }}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Paper className={classes.paper}>
            <Paper className={classes.header} elevation={10} square={true}>
              <p className={classes.title}>ログアウト</p>
            </Paper>
            <Paper className={classes.content} elevation={1} square={false}>
              <p className={classes.message}>ログアウトしますか？</p>
              <div style={{ marginTop: "20px", textAlign: "right" }}>
                <Button
                  style={{ marginRight: "1em" }}
                  onClick={(e) => {
                    setOpen(false);
                  }}
                  autoFocus
                >
                  キャンセル
                </Button>
                <CancelButton
                  variant="contained"
                  color="secondary"
                  style={{ fontWeight: "bold" }}
                  onClick={handleDelete}
                >
                  ログアウト
                </CancelButton>
              </div>
            </Paper>
          </Paper>
        </Fade>
      </Modal>
    </>
  );
};

export default DeleteModal;
