import React, { useRef, useMemo, FC, useEffect, useCallback } from "react";
import { useFrame, useThree } from "react-three-fiber";
import {
  Object3D,
  Geometry,
  Material,
  InstancedMesh,
  Color,
  VertexColors,
} from "three";

import { Lod, zf } from "../util/constant";

interface PointsProps {
  dataSet: plot[];
  // idx: number;
  nightMode: boolean;
  lod: Lod;
  labels: labels[];
}

const Plot: FC<PointsProps> = ({ dataSet, labels, nightMode, lod }) => {
  // プロットを描画するコンポーネント
  const { camera } = useThree();
  const mesh = useRef<InstancedMesh>(null);

  const searchedLen = dataSet.filter((plot) => {
    return plot.searched;
  }).length;

  useEffect(() => {
    mesh.current?.geometry.dispose();
    labels.forEach((label) => {
      if (label.cluster_id === label.size) {
        label.color = "#fff";
      }
    });
    // console.log("color", labels);
  }, [labels]);

  // 現在の拡大率の対象のラベルを取得
  const nowLabels = labels
    .filter((label) => {
      return (
        label.range[0] <= camera.zoom * zf + 3 &&
        (label.range[1] >= camera.zoom * zf + 3 || label.range[1] === -1)
      );
    })
    .map((label) => label.cluster_id);

  // 入力されたcluster_idが現在の拡大率の対象か判断
  const isNowLabel = useCallback(
    (cluster_id: number) => {
      return nowLabels.indexOf(cluster_id) !== -1 ? true : false;
    },
    [nowLabels]
  );

  // const hasNowLabels = useCallback(
  //   (cluster_ids: number[]) => {
  //     var t = nowLabels.filter((x) => new Set(cluster_ids).has(x));
  //     return t.length === 0 ? false : true;
  //   },
  //   [nowLabels]
  // );

  const getLabel = useCallback(
    (cluster_id: number) => {
      return labels.filter((label) => {
        return label.cluster_id === cluster_id;
      })[0];
    },
    [labels]
  );

  const hueCenter = nightMode ? 220 : 190;
  const range = 30;
  const parentSaturation = nightMode ? 60 : 75;
  const parentLightness = nightMode ? 40 : 70;

  const searchColor: any = useCallback(
    (
      cluster_ids: number[],
      cluster_id: number,
      searched: boolean | undefined
    ) => {
      // console.log("cluster_ids", cluster_ids);
      // console.log("cluster_id", cluster_id);
      const label = getLabel(cluster_id);
      // console.log("label", label);
      if (label.color) {
        // カラーがあった場合
        return label.color;
      } else {
        //親の色を取得
        const color = searchColor(
          cluster_ids,
          cluster_ids[cluster_ids.indexOf(cluster_id) + 1],
          searched
        );

        if (color === "#fff") {
          // rootの一つ下
          label.hue = Math.abs(
            Math.floor(Math.random() * range) + (hueCenter - range / 2)
          );
          label.saturation = parentSaturation;
          label.lightness = parentLightness;
          label.color = `hsl(${Math.abs(label.hue) % 360},${
            label.saturation
          }%,${label.lightness}%)`;
        } else {
          const reg = color.match(/hsl\((\d+),(\d+)%,(\d+)%\)/);
          if (nightMode) {
            // rootの２つ以上下
            label.hue = Math.max(
              200,
              Math.min(230, Math.floor(Number(reg[1]) - 1) % 360)
            );
            while (label.hue <= 180 && label.hue >= 200) {
              label.hue = Math.max(
                200,
                Math.min(230, Math.floor(Number(reg[1]) - 1) % 360)
              );
            }
            let sat = Math.max(
              nightMode ? 40 : 40,
              Math.min(100, nightMode ? reg[2] : reg[2] - 1)
            );
            let lig = Math.max(50, Math.min(70, reg[3] + 1));
            label.color = `hsl(${Math.abs(label.hue)},${Number(sat)}%,${Number(
              lig
            )}%)`;
          } else {
            label.hue = Math.abs(
              Math.floor(Math.random() * 50 + Number(reg[1])) % 360
            );

            let sat = Math.max(
              30,
              Math.min(100, searched ? reg[2] - 20 : reg[2] - 1)
            );

            let lig = Math.max(
              30,
              Math.min(90, searched ? reg[3] + 20 : reg[3])
            );

            label.color = `hsl(${Math.abs(label.hue)},${Number(sat)}%,${Number(
              lig
            )}%)`;
          }
        }
        return label.color;
      }
    },
    [getLabel, hueCenter, nightMode, parentLightness, parentSaturation]
  );

  const getColor = useCallback(
    (cluster_ids: number[], searched: boolean | undefined) => {
      if (searched) {
        return nightMode ? "#FFFFCC" : "red";
      }
      const nowLabel = cluster_ids.filter((cluster_id) => {
        return isNowLabel(cluster_id);
      });
      searchedLen.toFixed();
      if (nowLabel.length === 0) {
        // nowLabelじゃない場合
        const color = searchColor(cluster_ids, cluster_ids[0], searched);
        if (color === "#fff") {
          return color;
        } else {
          if (nightMode) {
            return color;
          } else {
            const reg = color.match(/hsl\((\d+),(\d+)%,(\d+)%\)/);
            return `hsl(${reg[1]},50%,90%)`;
          }
        }
      } else {
        // nowLabelの場合
        return searchColor(cluster_ids, nowLabel[0], searched);
      }
    },

    [isNowLabel, nightMode, searchColor, searchedLen]
  );

  const colors = useMemo(() => {
    searchedLen.toFixed();
    const array = new Float32Array(dataSet.length * 3);
    const color = new Color();
    dataSet.forEach((plot, idx) => {
      color
        .set(getColor(plot.cluster_id, plot.searched))
        .convertSRGBToLinear()
        .toArray(array, idx * 3);
    });

    return array;
  }, [dataSet, getColor, searchedLen]);

  const dummy = useMemo(() => new Object3D(), []);
  const particles = useMemo(() => {
    // alert("particle");
    searchedLen.toFixed();
    const temp = [];
    for (let point of dataSet) {
      const t = Math.random() * 100;
      const factor = 20 + Math.random() * 100;
      const speed = 0.01 + Math.random() / 200;
      const xFactor = point.coordinate[0] * 100;
      const yFactor = point.coordinate[1] * 100;
      let zFactor = Math.max(
        -500,
        Math.min(0, -1000 + point.cluster_id.length * 31)
      );
      zFactor = point.searched ? 10 : zFactor;
      const searched = point.searched;
      temp.push({
        t,
        factor,
        speed,
        xFactor,
        yFactor,
        zFactor,
        mx: 0,
        my: 0,
        searched,
      });
    }
    return temp;
  }, [dataSet, searchedLen]);

  useFrame(() => {
    particles.forEach((particle, i) => {
      let { t, speed, xFactor, yFactor, zFactor, searched } = particle;
      t = particle.t += speed / 2;
      const s = nightMode ? Math.cos(t) ** 2 + 0.2 : Math.cos(t * 4.5) ** 2 + 2;

      dummy.position.set(xFactor, yFactor, zFactor);
      // lod === Lod.Middle || lod === Lod.Overall
      //   ? dummy.scale.set(1, 1, 1)
      //   : dummy.scale.set(
      //       1 / camera.zoom + 0.3,
      //       1 / camera.zoom + 0.3,
      //       1 / camera.zoom + 0.3
      //     );
      // nightMode ? dummy.scale.set(s, s, s) : dummy.scale.set(1, 1, 1);

      searched || nightMode
        ? dummy.scale.set(s, s, s)
        : dummy.scale.set(2, 2, 2);

      nightMode
        ? dummy.rotation.set(s * 5, s * 5, s * 5)
        : dummy.rotation.set(0, 0, 0);
      dummy.updateMatrix();
      mesh.current?.setMatrixAt(i, dummy.matrix);
      camera.updateProjectionMatrix();
    });
    if (mesh.current === null) return;
    mesh.current.frustumCulled = false;
    mesh.current.instanceMatrix.needsUpdate = true;
  });

  return (
    <instancedMesh
      ref={mesh}
      args={[{} as Geometry, {} as Material, dataSet.length]}
    >
      {nightMode ? (
        <dodecahedronBufferGeometry attach="geometry" args={[1, 0]}>
          <instancedBufferAttribute
            attachObject={["attributes", "color"]}
            args={[colors, 3]}
          />
        </dodecahedronBufferGeometry>
      ) : (
        <sphereBufferGeometry attach="geometry" args={[2.5, 16, 16]}>
          <instancedBufferAttribute
            attachObject={["attributes", "color"]}
            args={[colors, 3]}
          />
        </sphereBufferGeometry>
      )}

      <meshStandardMaterial
        attach="material"
        vertexColors={VertexColors}
        opacity={0.7}
        transparent={!nightMode}
        emissive={new Color("#eee")}
        emissiveIntensity={0.1}
      />
    </instancedMesh>
  );
};

export default Plot;
